import { Component, Prop, Vue } from 'vue-property-decorator'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import AppIcon from '@/components/app/AppIcon.vue'
import { PageModule } from '@/store/page'

@Component
export default class TheBar extends Vue {
  @Prop({
    type: Object,
    required: true,
    default: () => ({
      href: undefined,
      icon: undefined,
      subtitle: undefined,
      title: undefined,
      to: undefined,
      badge: undefined
    })
  }) item!: any

  get computedText() {
    if (!this.item || !this.item.title) return ''

    let text = ''

    this.item.title.split(' ').forEach((value: string) => {
      text += value.substring(0, 1)
    })

    return text
  }

  get href() {
    return this.item.href || (!this.item.to ? '#' : undefined)
  }

  get isDark() {
    return PageModule.isDark
  }

  render() {
    return <v-list-item
      href={ this.href }
      rel={ this.href && this.href !== '#' ? 'noopener' : undefined }
      target={ this.href && this.href !== '#' ? '_blank' : undefined }
      to={ this.item.to }
      active-class={ `primary ${!this.isDark ? 'black' : 'white'}--text`}
    >
      <v-list-item-icon>
        <AppIcon
          class="menu-icon"
          name={ this.item.icon }
        />
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title domPropsInnerHTML={ this.item.title } />
        <span
          class="v-list-item-badge"
        >
          { this.item.badge }
        </span>
        <v-list-item-subtitle domPropsInnerHTML={ this.item.subtitle } />
      </v-list-item-content>
    </v-list-item>
  }
}
