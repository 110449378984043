import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import SelectionField from '@/components/general/SelectionField'
import { SecurityModule } from '@/store/security'
import { CreateElement } from 'vue/types/umd'
import { filter } from 'rxjs/operators'
import { $get } from '@/plugins/axios'
import { SecurityItem, HeaderItem, StatusHistory } from '@/models/security'
import { WebsocketEvent, WebsocketPayload } from '@/models'
import { Debounce } from '@/utils/helper'
import { AxiosError } from 'axios'

const defaultShowRows = [
  'passStatusId',
  'date',
  'data',
  'buildingTitle',
  'customerFullName',
  'updatedAt'
]

@Component

export default class SecurityTable extends Vue {
  @Prop({ type: String, default: null }) search!: string
  @Prop({ type: Object, required: true }) query!: Record<string, string>
  @Prop({ type: Boolean, default: true }) isAll!: boolean

  isLoading = false
  sortId: null | string = null
  sortType: null | string = null
  alignHeaders = 'left';
  showRows = JSON.stringify(defaultShowRows)

  get finalQuery() {
    const query = Object.assign({}, this.query)

    if (this.sortId && this.sortType) {
      query.order = this.sortId + ''
      query.sort = this.sortType + ''
    }
    query.searchQuery = this.search

    return query
  }

  @Debounce()
  @Watch('finalQuery', { deep: true })
  UpdateTable(value: string, oldValue: string) {
    if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
      this.loadPasses()
    }
  }

  get passes() {
    return SecurityModule[this.isAll ? 'passes' : 'permanentPasses']
  }

  async mounted() {
    await this.loadPasses(true)
    const handleEvents: WebsocketEvent[] = [
      WebsocketEvent.PASS_NEW,
      WebsocketEvent.PERMANENT_PASS_NEW,
      WebsocketEvent.PASS_UPDATE,
      WebsocketEvent.PERMANENT_PASS_NEW
    ]
    const filterSocket = filter<WebsocketPayload>((value) => handleEvents.includes(value.name))

    this.$socket.pipe(filterSocket).subscribe((event) => {
      this.loadPasses()
    })
  }

  get finalHeaders() {
    return this.headers.reduce<HeaderItem[]>((acc, header) => {
      if (this.showRows.includes(header.value)) {
        acc.push(header)
      }

      return acc
    }, [])
  }

  @Debounce()
  async loadPasses(loadMore = false) {
    if (!this.isAll) {
      this.isLoading = true
      await SecurityModule.getPermanentPasses({ loadMore, query: this.finalQuery, isAll: this.isAll })
      this.isLoading = false
    } else {
      this.isLoading = true
      await SecurityModule.getPasses({ loadMore, query: this.finalQuery, isAll: this.isAll })
      this.isLoading = false
    }
  }

  async loadMorePasses(entries: Array<IntersectionObserverEntry>) {
    if (entries[0].isIntersecting && !this.isLoading && this.passes.nextRow !== -1) {
      await this.loadPasses(true)
    }
  }

  async toggleHistory(pass: { statusHistory: StatusHistory[]; id: number; _showDetails: boolean; tenantId: number }) {
    pass._showDetails = !pass._showDetails
    if (pass) {
      this.isLoading = true
      if (this.isAll) {
        if (pass.statusHistory === undefined) {
          await $get(`/pass/${pass.id}`, { params: { tenantId: pass.tenantId } })
            .then((data) => {
              pass.statusHistory = data.statusHistory
            })
            .catch((e) => {
              return SecurityModule.SEND_NOTIFICATION({ type: 'error', message: (e as AxiosError)?.response?.data?.userMessages[0] || 'Ошибка в запросе' })
            })
            .finally(() => {
              this.isLoading = false
            })
        } else {
          this.isLoading = false
          return false
        }
      } else {
        if (pass.statusHistory === undefined) {
          await $get(`/permanent-pass/${pass.id}`, { params: { tenantId: pass.tenantId } })
            .then((data) => {
              pass.statusHistory = data.statusHistory
            })
            .catch((e) => {
              return SecurityModule.SEND_NOTIFICATION({ type: 'error', message: (e as AxiosError)?.response?.data?.userMessages[0] || 'Ошибка в запросе' })
            })
            .finally(() => {
              this.isLoading = false
            })
        } else {
          this.isLoading = false
          return false
        }
      }
    }
  }

  headers: HeaderItem[] = [
    this.isAll ? {
      value: 'passStatusTitle',
      align: this.alignHeaders,
      text: 'Статус',
      cellClass: 'w-10',
      sortable: true
    } : {
      value: 'permanentPassStatusTitle',
      align: this.alignHeaders,
      text: 'Статус',
      cellClass: 'w-10',
      sortable: true
    },
    this.isAll ? {
      value: 'date',
      align: this.alignHeaders,
      cellClass: 'w-10',
      text: 'Дата прибытия',
      sortable: true
    } : {
      value: 'date',
      align: this.alignHeaders,
      cellClass: 'w-10',
      text: 'Действует до',
      sortable: true
    },
    {
      value: 'data',
      align: this.alignHeaders,
      cellClass: 'w-20',
      text: 'Данные пропуска',
      sortable: true
    },
    {
      value: 'buildingTitle',
      align: this.alignHeaders,
      cellClass: 'w-15',
      text: 'Объект',
      sortable: true
    },
    {
      value: 'customerFullName',
      align: this.alignHeaders,
      cellClass: 'w-30',
      text: 'Заявитель',
      sortable: true
    },
    {
      value: 'updatedAt',
      align: this.alignHeaders,
      text: 'Обновлено',
      cellClass: 'border-top w-10',
      sortable: true
    }
  ]

  expanded = []

  tab = 'tab-pass'

  render(h: CreateElement) {
    return (
      <div>
        {h('v-data-table', {
          props: {
            headers: this.headers,
            items: this.passes.items,
            expanded: this.expanded,
            showExpand: true,
            'hide-default-footer': true,
            'disable-pagination': true
          },
          class: {
            'table--loading': this.isLoading
          },
          on: {
            'item-expanded': ({ item, value }: { item: SecurityItem; value: boolean }) => {
              this.toggleHistory(item)
            }
          },
          scopedSlots: {
            footer: () => {
              return <div v-intersect={ this.loadMorePasses }></div>
            },
            'expanded-item': ({ item }: { item: SecurityItem }) => {
              if (item.statusHistory && item.statusHistory.length && item._showDetails) {
                return <td colspan={ this.headers.length } class="pl-auto">
                  <h4 class="mt-2 mb-2 history-title">История изменений</h4>
                  {
                    item.statusHistory.map((status) => {
                      return <div style="margin-bottom: 10px" class="pass-status__chip">
                        <v-chip
                          color={ this.isAll ? status.passStatusColor : status.permanentPassStatusColor }
                          text-color="black"
                          x-small
                        >
                          { this.isAll ? status.passStatusTitle : status.permanentPassStatusTitle }
                        </v-chip>
                        <span class="pass-status__chip-title"><small>{ this.$options.filters?.dateTime(status.createdAt) } { status.userName ? ' (' + status.userName + ')' : '' }</small></span>
                      </div>
                    })
                  }
                </td>
              }
            },
            'item.passStatusTitle': ({ item }: { item: SecurityItem }) => {
              return <v-chip color={ item.passStatusColor } text-color="black"> { item.passStatusTitle } </v-chip>
            },
            'item.permanentPassStatusTitle': ({ item }: { item: SecurityItem }) => {
              return <v-chip color={ item.permanentPassStatusColor } text-color="black"> { item.permanentPassStatusTitle } </v-chip>
            },
            'item.date': ({ item }: { item: SecurityItem }) => {
              if (item.date === null) {
                return <small>Бессрочно</small>
              } else {
                return <small>{ this.$options.filters?.dateOnly(item.date) }</small>
              }
            },
            'item.data': ({ item }: { item: SecurityItem }) => {
              return <div>
                { item.data }
                <br v-show={ item.carTypeTitle } />
                { item.carTypeTitle }
                <br v-show={ item.passAccessLevelTitle } />
                { item.passAccessLevelTitle }
              </div>
            },
            'item.customerFullName': ({ item }: { item: SecurityItem }) => {
              return <div>
                <SelectionField text={ item.customerFullName } />
                { item.customerStatus ? <small> ({ item.customerStatus }) </small> : null }
                <br/>
                { item.customerPhoneNumber || '-' }
                <br/>
                { item.placeAddress }
                { item.customerDebt > 0
                  ? [<br/>, <small> долг <span class="text-error"> { this.$options.filters?.moneyFormat(item.customerDebt) }</span></small>]
                  : null }
              </div>
            },
            'item.updatedAt': ({ item }: { item: SecurityItem }) => {
              return item.updatedAt
                ? <small>{ this.$options.filters?.fullDateTime(item.updatedAt) }</small>
                : <small>{ this.$options.filters?.fullDateTime(item.createdAt) }</small>
            }
          }
        })}
      </div>
    )
  }
}
