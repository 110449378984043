import { Component, Vue } from 'vue-property-decorator'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import AppIcon from '@/components/app/AppIcon.vue'
import { UserModule } from '@/store/user'
import { HandleApiRequest } from '@/utils/helper'

@Component({
  components: {
    AppIcon
  }
})
export default class LoginView extends Vue {
  isLoading = false
  viewPassword = false
  email = ''
  password = ''
  isValidForm = false

  @HandleApiRequest()
  async authHandler() {
    await UserModule.login({ password: this.password, email: this.email })

    this.$router.push({ name: 'home' })
  }

  toggleViewPassword() {
    this.viewPassword = !this.viewPassword
  }

  require(value: string) {
    return !!value || 'Поле необходимо для заполнения'
  }

  created() {
    this.$vuetify.theme.dark = true
  }

  beforeDestroy() {
    this.$vuetify.theme.dark = false
  }

  render() {
    return <div class="block login-view">
      <v-form {...{
        props: {
          value: this.isValidForm,
          'lazy-validation': true
        },
        on: {
          input: (value: boolean) => {
            this.isValidForm = value
          },
          submit: (event: Event) => {
            event.preventDefault()
            this.authHandler()
          }
        }
      }}>
        <h3 class="title ta-center"> Добро пожаловать </h3>
        <v-text-field
          color="secondary"
          onInput={ (value: string) => { this.email = value }}
          label="Email"
          autofocus={true}
          rules={ [this.require] }
          type="email"
        />
        <v-text-field
          value={this.password}
          color="secondary"
          label="Пароль"
          onInput={ (value: string) => { this.password = value }}
          rules={ [this.require] }
          type={this.viewPassword ? 'text' : 'password'}
        >
          <AppIcon
            onClick={this.toggleViewPassword}
            name="eye"
            class={{ hide: !this.viewPassword }}
            slot="append"
          ></AppIcon>
        </v-text-field>

        <div class="d-flex ai-center fd-column mt-2">
          <v-btn
            disabled={ !this.isValidForm }
            loading={ this.isLoading }
            large
            depressed
            type="submit"
            color="primary"
            class="v-btn--text white--text"
          >
            Далее
          </v-btn>
          <v-btn small text class="mt-1" link to={ '/reset' }> Забыли пароль? </v-btn>
        </div>
      </v-form>
    </div>
  }
}
