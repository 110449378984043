/* eslint-disable @typescript-eslint/consistent-type-assertions */
import Vue from 'vue'
import router from '@/router'
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 15000,
  headers: {
    withCredentials: true
  }
})

Vue.prototype.$axios = axios

instance.interceptors.request.use(async(config) => {
  const host = window.location.hostname
  const appName = host === 'localhost' ? 'call-center-stage.domyland.dev' : host
  config.headers.AppName = appName
  config.headers.AppVersion = '2.78.0'
  config.headers.TimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  let token = localStorage.getItem('token')

  token = localStorage.getItem('token')

  if (token) {
    config.headers.authorization = token
  }

  return config
}, (error) => {
  return Promise.reject(error)
})

instance.interceptors.response.use((response) => {
  return response
}, async(error: AxiosError) => {
  const code = error.response?.status || 400

  if (code === 401) {
    localStorage.removeItem('token')
    router.push('/login')
  }

  return Promise.reject(error)
})

type ApiLAyout<D> = D

function getData(request: any) {
  const response = request.data
  return response.data || response
}

export const get = <D = any>(url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse<D>> => instance.get(url, config)
export const put = <D = any>(url: string, data: Record<string, any> | null = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse<D>> => instance.put(url, data, config)
export const post = <D = any>(url: string, data: Record<string, any> | null = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse<D>> => instance.post(url, data, config)
export const patch = <D = any>(url: string, data: Record<string, any> | null = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse<D>> => instance.patch(url, data, config)
export const remove = <D = any>(url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse<D>> => instance.delete(url, config)

export const $get = <D = any, L = ApiLAyout<D>>(url: string, config: AxiosRequestConfig = {}): Promise<L> => {
  return <Promise<L>>instance.get(url, config)
    .then(getData)
}

export const $put = <D = any, L = ApiLAyout<D>>(url: string, data: Record<string, any> | null = {}, config: AxiosRequestConfig = {}): Promise<L> => {
  return <Promise<L>>put(url, data, config)
    .then(getData)
}

export const $post = <D = any, L = ApiLAyout<D>>(url: string, data: Record<string, any> | null = {}, config: AxiosRequestConfig = {}): Promise<L> => {
  return <Promise<L>>post(url, data, config)
    .then(getData)
}

export const $patch = <D = any, L = ApiLAyout<D>>(url: string, data: Record<string, any> | null = {}, config: AxiosRequestConfig = {}): Promise<L> => {
  return <Promise<L>>patch(url, data, config)
    .then(getData)
}

export const $remove = <D = any, L = ApiLAyout<D>>(url: string, config: AxiosRequestConfig = {}): Promise<L> => {
  return <Promise<L>>remove(url, config)
    .then(getData)
}
