import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { CreateElement } from 'vue'
import { ScopedSlot } from 'vue/types/vnode'
import { FormatSelectorItem, InputSelectorItem } from '@/models'
import { Debounce, HandleApiRequest } from '@/utils/helper'
import { $get } from '@/plugins/axios'

@Component
export default class AppFilterSelector extends Vue {
  @Prop({ type: String }) url!: string
  @Prop({ type: String }) label!: string
  @Prop({ type: Array, default: () => [] }) options!: InputSelectorItem[]
  @Prop({ type: Object, default: () => ({}) }) query!: Record<string, string>
  @Prop({ type: Function }) reduceItems!: (items: InputSelectorItem[]) => InputSelectorItem[]
  @Prop({ type: Boolean, default: true }) showImage!: boolean
  @Prop({ type: Boolean, default: true }) preImage!: boolean
  @Prop({}) showEmpty!: boolean
  @Prop({ type: [Array, Number, String], default: () => [] }) value!: number[] | number | string
  @Prop({ type: Number, default: 7 }) minimumResultsForSearch!: number;
  @Prop({ type: Object, default: () => ({}) }) watchQuery!: Record<string, string>;
  @Prop({ type: Boolean, default: true }) watchProps!: boolean;

  items: InputSelectorItem[] = []
  preItems: InputSelectorItem[] = []
  isLoading = false
  isLoaded = false
  searchQuery = ''
  nextRow = 0

  @Debounce()
  @Watch('searchQuery')
  handleSearchQuery() {
    this.nextRow = 0
    this.loadItems()
  }

  @Watch('watchQuery')
  handleWatchQuery(newValue: Record<string, string>, oldValue: Record<string, string>) {
    if ((JSON.stringify(newValue) !== JSON.stringify(oldValue)) && this.watchProps) {
      this.isLoaded = false
      this.nextRow = 0
      this.loadItems()
    }
  }

  get hasSearch() {
    return this.searchQuery || this.allItems.length > this.minimumResultsForSearch
  }

  get isDisabled() {
    return Object.prototype.hasOwnProperty.call(this.$attrs, 'disabled') && !['false', false].includes(this.$attrs.disabled)
  }

  get isMultiple() {
    return Object.prototype.hasOwnProperty.call(this.$attrs, 'multiple') && !['false', false].includes(this.$attrs.multiple)
  }

  get isChips() {
    return Object.prototype.hasOwnProperty.call(this.$attrs, 'chips') && !['false', false].includes(this.$attrs.chips)
  }

  get finalValue() {
    return this.value
  }

  get fullValue() {
    return this.formattedItems.find(({ value }) => value === this.finalValue)
  }

  get hasItemAddress() {
    return !!this.formattedItems.find(({ address }) => address) || false
  }

  get hasItemCity() {
    return !!this.formattedItems.find(({ city }) => city) || false
  }

  get hasItemAvatar() {
    return !!this.formattedItems.find(({ avatar }) => avatar) || false
  }

  get hasItemImage() {
    return !!this.formattedItems.find(({ image }) => image) || false
  }

  get hasItemSubtitle() {
    return !!this.formattedItems.find(({ subTitle }) => subTitle) || false
  }

  get needEmptyValue() {
    return this.$attrs.chips === undefined
  }

  get innerValue() {
    if (Array.isArray(this.value)) {
      return this.allItems.filter(item => (this.value as (number | string)[]).includes(item.id))
    }
    const value = this.allItems.find(item => item.id === this.value)
    return value ? [value] : null
  }

  get allItems() {
    const emptyValue = {
      title: '-',
      id: ''
    } as InputSelectorItem
    const showEmptyValue = this.showEmpty !== undefined ? this.showEmpty : !this.isMultiple

    return [this.isLoaded && showEmptyValue && emptyValue, ...(this.preItems || []), ...this.items]
      .filter(Boolean)
  }

  get formattedItems(): FormatSelectorItem[] {
    const all = this.reduceItems ? this.reduceItems(this.allItems) : this.allItems

    return all
      .map(this.formatItem)
      .filter((item) => {
        if (!this.url && this.searchQuery) {
          return item.text.toLowerCase().includes(this.searchQuery.toLowerCase())
        }
        return true
      })
  }

  mounted() {
    this.preItems = JSON.parse(JSON.stringify(this.options))
  }

  async handleFocus() {
    if (!this.isLoaded) {
      await this.loadItems()
    } else {
      this.scrollToStart()
    }
  }

  scrollToStart() {
    const selector = this.$refs.selector as any

    if (selector) {
      const menu = selector.$refs.menu
      const content = menu.$refs.content

      setTimeout(() => {
        content.scroll(0, 0)
      }, 100)
    }
  }

  openSelector() {
    const selector = this.$refs.selector as any
    if (selector) {
      selector.$refs.input.click()
    }
  }

  formatItem(item: number | InputSelectorItem): FormatSelectorItem {
    if (typeof item === 'number') {
      return {
        text: item.toString(),
        value: item
      }
    }

    return {
      address: item.address || '',
      text: item.title || item.text || item.fullName || '',
      value: Number(item.id) > 0 ? Number(item.id) : item.id,
      color: item.color,
      phone: item.phoneNumber,
      avatar: item.fullName ? item.image : undefined,
      image: item.customerImage || item.image,
      city: item.city || ''
    }
  }

  getSelected() {
    return this.innerValue ? this.innerValue : null
  }

  @HandleApiRequest()
  async loadItems(loadMore = false) {
    if (this.url === undefined) {
      this.isLoaded = true
      return
    }

    this.isLoading = true
    const params = { ...this.query, ...this.watchQuery, searchQuery: this.searchQuery, fromRow: this.nextRow }

    const { items, nextRow } = await $get(`${this.url}`, { params })
    this.items = loadMore ? this.items.concat(items) : items

    this.nextRow = nextRow || -1
    this.isLoading = false
    this.isLoaded = true
  }

  async loadMoreItems(entries: Array<IntersectionObserverEntry>) {
    if (entries[0].isIntersecting && !this.isLoading && ![-1, 0].includes(this.nextRow)) {
      await this.loadItems(true)
    }
  }

  setSelected(items: InputSelectorItem[]) {
    this.preItems = items

    if (this.isDisabled) {
      return
    }

    const formattedItems = (items || []).map(this.formatItem)
    const values = formattedItems.map((item) => item.value)
    this.$emit('input', this.isMultiple ? values : values[0] || '')
  }

  setWatchQuery(watchQuery: Record<string, string>) {
    this.watchQuery = watchQuery
  }

  buildSelectionItem(): ScopedSlot | undefined {
    if (this.isMultiple) {
      return undefined
    } else if (this.hasItemAvatar) {
      return ({ item }: { item: FormatSelectorItem }) => {
        return <div class='d-flex'>
          { item.avatar && <v-img src={ item.avatar } width='25px' height='25px' class='mr-2 circle' /> }
          <div>
            <p class='mb-1 fs-2'> { item.text } </p>
            <p class='text-description'> { item.phone } </p>
          </div>
        </div>
      }
    } else if (this.hasItemImage) {
      return ({ item }: { item: FormatSelectorItem }) => {
        return <div class='d-flex'>
          { item.image && this.showImage ? <v-img src={ item.image } width='25px' height='25px' class='mr-2 circle' /> : null }
          <div>
            <p class='mb-1 fs-2'> { item.text } </p>
          </div>
        </div>
      }
    } else if (this.hasItemSubtitle) {
      return ({ item, on }: { item: FormatSelectorItem; on: Record<string, Function> }) => {
        return <v-list-item { ...{ on }}>
          <v-list-item-content>
            <v-list-item-title class="ws-normal"> { item.text } </v-list-item-title>
            <v-list-item-subtitle class="ws-normal"> { item.subTitle } </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      }
    } else if (this.hasItemAddress) {
      return ({ item }: { item: FormatSelectorItem }) => {
        return <div class='d-flex'>
          <div>
            <p class="mb-1 fs-2">{ item.address }</p>
          </div>
        </div>
      }
    }

    return undefined
  }

  render(h: CreateElement) {
    return h('v-select',
      {
        props: {
          items: this.formattedItems,
          label: this.label,
          outlined: true,
          value: this.finalValue,
          ...this.$attrs
        },
        ref: 'selector',
        class: ['app-filter-selector'],
        scopedSlots: {
          selection: ({ item }) => {
            if (this.isMultiple) {
              return h('v-chip',
                {
                  props: {
                    color: item.color,
                    close: true,
                    'text-color': item.color ? 'black' : '',
                    'close-icon': 'mdi-close'
                  },
                  on: {
                    'click:close': () => {
                      if (Array.isArray(this.value)) {
                        this.$emit('input', this.value.filter(value => {
                          return value !== item.value
                        }))
                      } else {
                        console.error('Error multiple close')
                      }
                    }
                  }
                },
                [<p class='text-ellipsis ma-0'> { item.text } </p>]
              )
            } else if (this.hasItemCity && this.hasItemAddress) {
              return <div class='d-flex'>
                <div>
                  <p class="mb-1 fs-2">{ item.text }</p>
                </div>
              </div>
            } else if (this.hasItemAddress) {
              return <div class='d-flex'>
                <div>
                  <p class="mb-1 fs-2">{ item.address }</p>
                </div>
              </div>
            }

            return <div class='d-flex ai-center w-100'>
              { item.image && this.showImage ? <v-img src={ item.image } width='25px' height='25px' class='mr-2 circle flex-grow-0' /> : null }
              <span class="text-ellipsis"> { item.text } </span>
            </div>
          },
          item: this.buildSelectionItem()
        },
        on: {
          focus: this.handleFocus,
          input: (event: number | number[]) => {
            this.$emit('input', event)
          },
          ...this.$listeners
        }
      },
      [
        /* @ts-ignore */
        <h2 slot='append-item'>
          <div v-intersect={ this.loadMoreItems }></div>
          { this.isLoading && this.isLoaded ? <div class='ta-center'><v-progress-circular indeterminate color='cyan' /></div> : undefined }
        </h2>,
        /* @ts-ignore */
        this.hasSearch && <div slot='prepend-item' class='px-2'>
          <v-text-field
            small
            dense
            placeholder='Поиск'
            outlined
            value={ this.searchQuery }
            onInput={ (event: string) => { this.searchQuery = event } }
          ></v-text-field>
        </div>,
        /* @ts-ignore */
        <div slot='no-data'>
          { [this.isLoading ? <div class='ta-center'><v-progress-circular indeterminate color='cyan' /></div> : undefined] }
        </div>
      ]
    )
  }
}
