import { Component, Vue } from 'vue-property-decorator'
import { PageModule } from '@/store/page'

import noneLayout from '@/layout/none'
import defaultLayout from '@/layout/default'
import { CreateElement } from 'vue'

@Component({
  components: {
    noneLayout,
    defaultLayout
  }
})
export default class MainApp extends Vue {
  get layout() {
    return PageModule.layout
  }

  render() {
    const body = <transition name="fade-in-down">
      <router-view />
    </transition>

    const components: { [k: string]: any } = {
      none: <noneLayout> { body } </noneLayout>,
      default: <defaultLayout> { body } </defaultLayout>
    }
    const component = components[this.layout]

    return component
  }
}
