import { Component, Vue } from 'vue-property-decorator'
import LoginView from '@/components/page/login/LoginView'
import { $post } from '@/plugins/axios'
import { HandleApiRequest } from '@/utils/helper'
import { PageModule } from '@/store/page'

@Component({
  components: {
    LoginView
  },
  metaInfo: {
    script: [
      { src: 'https://www.google.com/recaptcha/api.js', async: true, defer: true }
    ]
  }
})
export default class LoginPage extends Vue {
  isLoading = false
  viewPassword = false
  email = ''
  password = ''
  isValidForm = false
  isSent = false

  @HandleApiRequest()
  async reset() {
    await $post('auth/resetPassword', {
      email: this.email,
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      captcha: grecaptcha.getResponse()
    })

    PageModule.SEND_NOTIFICATION({ type: 'success', message: 'На указанный адрес электронной почты отправлено письмо с инструкцией по установке пароля' })

    this.isSent = true
  }

  toggleViewPassword() {
    this.viewPassword = !this.viewPassword
  }

  require(value: string) {
    return !!value || 'Поле необходимо для заполнения'
  }

  render() {
    return (
      <div class="fullscreen fullscreen--login">
        <div class="block login-view">
          <v-form {...{
            props: {
              disabled: this.isSent,
              value: this.isValidForm,
              'lazy-validation': true
            },
            on: {
              input: (value: boolean) => {
                this.isValidForm = value
              },
              submit: (event: Event) => {
                event.preventDefault()
                this.reset()
              }
            }
          }}>
            <h3 class="title mb-2 ta-center"> Восстановление доступа </h3>
            <p class="ta-center text-description"> Укажите Email адрес, для которого хотите восстановить пароль. </p>
            <v-text-field
              color="secondary"
              onInput={ (value: string) => { this.email = value }}
              label="Email"
              class="mb-2"
              autofocus={true}
              rules={ [this.require] }
              type="email"
            />
            <div class="g-recaptcha" data-sitekey="6Lc4F1YUAAAAACrTSe5UTceBNfPEqyQZi2uBdrIa" />

            <div class="d-flex ai-center fd-column mt-2">
              <v-btn
                disabled={ !this.isValidForm }
                loading={ this.isLoading }
                large
                depressed
                type="submit"
                color="primary"
                class="v-btn--text white--text"
              >
                Далее
              </v-btn>
              <v-btn small text class="mt-1" link to={ '/login' }> вернуться </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    )
  }
}
