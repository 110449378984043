import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'
import store from '@/store/index'
import { $get, $post, $remove } from '@/plugins/axios'
import { CurrentUser } from '@/models/user'
import { Alert, TAlertType } from './security'

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule {
  user: null | CurrentUser = null
  isInited = false

  alert: Alert = {
    type: 'success',
    message: '',
    show: false
  }

  @Mutation
  SET_STATE_USER<S extends this, K extends keyof this>({ key, value }: { key: K; value: S[K] }) {
    this[key] = value
  }

  @Mutation
  SEND_NOTIFICATION({ type, message }: { type: TAlertType; message: string }) {
    this.alert = { type, message, show: true }
  }

  @Action
  async getUser() {
    const user = await $get<CurrentUser>('/currentuser')

    this.SET_STATE_USER({ key: 'user', value: user })
  }

  @Action
  async login(auth: { password: string; email: string }) {
    const { token } = await $post<{ token: string }>('/auth', auth)

    if (token) {
      localStorage.setItem('token', token)
    }
  }

  @Action
  async uploadFile(file: File) {
    const formData = new FormData()
    formData.append('file[]', file)

    const response = await $post('', formData, { baseURL: process.env.VUE_APP_FILE_UPLOAD || 'https://uploads.domyland.com' })

    return response
  }

  @Action
  async logout() {
    await $remove('auth')
    localStorage.removeItem('token')
  }
}

export const UserModule = getModule(User)
