import { Component, Vue, Watch } from 'vue-property-decorator'
import SecurityTable from './SecurityTable'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import AppDatePicker from '@/components/app/AppDatePicker.vue'
import AppFilterSelector from '@/components/app/AppFilterSelector'
import AppFilterSelectorPlaces from '@/components/app/AppFilterSelectorPlaces'
import SelectionField from '@/components/general/SelectionField'
import { $get, $post } from '@/plugins/axios'
import { PassForm } from '@/models/service'
import { filter } from 'rxjs/operators'
import { UserModule } from '@/store/user'
import DatePicker from '@/components/general/DatePicker'
import { WebsocketEvent, WebsocketPayload } from '@/models'
import { HandleError } from '@/utils/helper'
import { SecurityModule } from '@/store/security'
import { AxiosError } from 'axios'

@Component({
  components: {
    AppDatePicker,
    SecurityTable,
    SelectionField,
    AppFilterSelector,
    DatePicker
  }
})

export default class SecurityTabs extends Vue {
  searchField = ''
  searchNewField = ''
  query: Record<string, (number | string)[] | number | string | undefined> = JSON.parse(localStorage.getItem('passSettingsSearch') || '{}')
  cachedValues: Record<string, number[]> = JSON.parse(localStorage.getItem('passSettingsCache') || '{}')
  viewSettings = Object.keys(this.cachedValues).length > 0
  viewCreatePassModal = false
  pass: Record<string, number[] | number | string | undefined | null> = { carTypeId: 1 }
  passForm: null | PassForm = null
  passGetFormLoading = false
  isUnlimited = []
  isSelectWatchProps = true
  oldData: Record<string, number | number[] | string[] | string | undefined | null> = { buildingId: 0, tenantId: 0, placeId: 0, customerId: 0 }

  securityData = {}

  dict = {
    passAccessLevel: [
      { id: 1, title: 'Гость' },
      { id: 2, title: 'Строитель' },
      { id: 3, title: 'Курьер' }
    ],
    date: [
      { id: 'today', title: 'Сегодня' }
    ]
  }

  created() {
    const tab = localStorage.getItem('securityTab') || 'tab-pass'

    this.tab = tab

    const handleEvents: WebsocketEvent[] = [WebsocketEvent.PASS_NEW]
    const filterSocket = filter<WebsocketPayload>((value) => handleEvents.includes(value.name))

    this.$socket.pipe(filterSocket).subscribe((event) => {
      this.onNewPass(event.data)
    })
  }

  tab = 'tab-pass'

  @Watch('pass.buildingId')
  clearFormFieldsBuildingIdChange(newVal: any, oldVal: any) {
    if (oldVal && this.oldData.buildingId === 0 && this.oldData.tenantId === 0) {
      this.resetPassForm()
    }
  }

  @Watch('pass.tenantId')
  clearFormFieldsTenantIdChange(newVal: any, oldVal: any) {
    if (oldVal && this.oldData.tenantId === 0) {
      this.resetPassForm()
    }
  }

  @Watch('pass.tenantId')
  clearForm() {
    if (this.pass.tenantId === '') this.pass = { carTypeId: 1 }
  }

  get tabs() {
    return [
      {
        title: 'Разовые',
        value: 'tab-pass'
      },
      {
        title: 'Постоянные',
        value: 'tab-permanent-pass'
      }
    ]
  }

  async onNewPass(data: any): Promise<void> {
    await this.$nextTick()
  }

  get user() {
    return UserModule.user
  }

  get finalQuery() {
    localStorage.setItem('passSettingsSearch', JSON.stringify(this.query))

    const query = Object.entries(this.query).reduce<Record<string, string>>((acc, [key, value]) => {
      const keyValue = (value || [])
      acc[key] = keyValue + ''

      return acc
    }, {})

    return query
  }

  get passDate() {
    if ((this.pass.isUnlimited) || ((this.pass.date && (this.pass.date !== undefined && this.pass.date !== -10800)))) {
      return false
    } return true
  }

  @Watch('query', { deep: true })
  HandleQueryChange() {
    this.$nextTick(() => {
      const keys = Object.keys(this.query)

      if (keys.length === 0) {
        localStorage.setItem('passSettingsCache', '{}')
        return
      }

      keys.forEach(key => {
        const selector = (this.$refs as any)[`filter-${key}`]

        if (selector) {
          this.cachedValues[key] = selector.getSelected()
        }
      })

      localStorage.setItem('passSettingsCache', JSON.stringify(this.cachedValues))
    })
  }

  @Watch('query.tenantId', { deep: true })
  resetQuery() {
    if (this.query.tenantId === '') this.query = {}
  }

  toggleSettingsView() {
    this.viewSettings = !this.viewSettings
  }

  async createPass() {
    const data = Object.assign({}, this.pass)
    for (const prop in data) {
      if (typeof this.$refs[prop] !== 'undefined') {
        if (!this.$refs[prop]) {
          return
        }
      }
    }
    if (localStorage.getItem('securityTab') === 'tab-pass') {
      try {
        await $post('/pass', data, { params: { tenantId: this.pass.tenantId } })
        await this.$nextTick()
        this.isSelectWatchProps = false
        this.viewCreatePassModal = false
        this.pass = { carTypeId: 1 }
      } catch (error) {
        return SecurityModule.SEND_NOTIFICATION({ type: 'error', message: (error as AxiosError)?.response?.data?.userMessages[0] || 'Ошибка в запросе' })
      } finally {
        /* @ts-ignore */
        this.$refs.picker.clearHandler()
        this.pass.date = -10800
      }
    } else {
      try {
        await $post('/permanent-pass', data, { params: { tenantId: this.pass.tenantId } })
        await this.$nextTick()
        this.isSelectWatchProps = false
        this.viewCreatePassModal = false
        this.pass = { carTypeId: 1 }
      } catch (error) {
        return SecurityModule.SEND_NOTIFICATION({ type: 'error', message: (error as AxiosError)?.response?.data?.userMessages[0] || 'Ошибка в запросе' })
      } finally {
        /* @ts-ignore */
        this.$refs.picker.clearHandler()
        this.pass.date = -10800
      }
    }
  }

  resetPassForm(): void {
    this.pass = {
      tenantId: this.pass.tenantId,
      buildingId: this.pass.buildingId,
      placeId: null,
      customerId: null,
      carTypeId: null,
      passTypeId: null,
      passAccessLevelId: null,
      human: '',
      carModel: '',
      regNumber: '',
      date: -10800,
      comment: ''
    }
  }

  openCreatePassModal(): void {
    this.isSelectWatchProps = true
    this.viewCreatePassModal = true
  }

  render() {
    return <div>
      <v-dialog
        max-width="600px"
        max-height="90vmin"
        value={ this.viewCreatePassModal }
        onInput={ (value: boolean) => { this.viewCreatePassModal = value } }
      >
        <v-card>
          <v-card-title> Добавить пропуск </v-card-title>
          <v-card-text>
            <AppFilterSelector
              label='Управляющая организация'
              dense
              hide-details
              options={ this.user?.tenants }
              value={ this.pass.tenantId }
              onInput={ (value: number[]) => { this.pass = { ...this.pass, tenantId: value } } }
            />
            <AppFilterSelector
              label='Объект'
              url='buildings'
              ref='passForm-buildingId'
              dense
              hide-details
              disabled={ !this.pass.tenantId }
              show-image={ false }
              query={ { tenantId: this.pass.tenantId } }
              watch-query={ { tenantId: this.pass.tenantId || '' } }
              watch-props={ this.isSelectWatchProps }
              value={ this.pass.buildingId }
              onInput={ (value: number) => { this.pass = { ...this.pass, buildingId: value } } }
            />
            <v-row>
              <v-col cols="6">
                <AppFilterSelectorPlaces
                  label='Помещение'
                  dense
                  hide-details
                  disabled={ !this.pass.tenantId }
                  show-empty={ false }
                  url='places'
                  ref='passForm-placeId'
                  query={ { tenantId: this.pass.tenantId } }
                  watch-query={ { buildingIds: this.pass.buildingId || '' } }
                  value={ this.pass.placeId }
                  watch-props={ this.isSelectWatchProps }
                  options={ [{ title: '-- Неизвестно --', id: 0 }] }
                  onInput={ (value: number[]) => { this.pass = { ...this.pass, placeId: value } } }
                />
              </v-col>
              <v-col cols="6">
                <AppFilterSelector
                  label='Заявитель'
                  dense
                  hide-details
                  url='customers'
                  ref='passForm-customerId'
                  disabled={ !this.pass.tenantId }
                  show-empty={ false }
                  query={ {
                    buildingId: this.pass.buildingId || '',
                    tenantId: this.pass.tenantId
                  } }
                  watch-query={ { placeId: this.pass.placeId || '' } }
                  watch-props={ this.isSelectWatchProps }
                  options={ [{ title: '-- Не зарегистрирован --', id: 0 }] }
                  value={ this.pass.customerId }
                  onInput={ (value: number[]) => {
                    this.pass = { ...this.pass, customerId: value }
                  } }
                />
              </v-col>
            </v-row>
            <AppFilterSelector
              label='Тип пропуска'
              url='pass-types'
              dense
              hide-details
              ref='passForm-passTypeId'
              value={ this.pass.passTypeId }
              onInput={ (value: number[]) => { this.pass = { ...this.pass, passTypeId: value } } }
            />
            <AppFilterSelector
              v-show={ this.pass.passTypeId === 1 }
              label='Тип автомобиля'
              url='car-types'
              dense
              hide-details
              ref='passForm-carTypeId'
              value={ this.pass.carTypeId }
              onInput={ (value: number[]) => { this.pass = { ...this.pass, carTypeId: value } } }
            />
            <AppFilterSelector
              v-show={ this.pass.passTypeId === 2 && localStorage.getItem('securityTab') === 'tab-pass'}
              label='Уровень доступа'
              dense
              hide-details
              options={ this.dict.passAccessLevel }
              ref='passForm-passAccessLevelId'
              value={ this.pass.passAccessLevelId }
              onInput={ (value: number[]) => { this.pass = { ...this.pass, passAccessLevelId: value } } }
            />
            <v-text-field
              v-show={ this.pass.passTypeId === 2 }
              dense
              color="secondary"
              label="ФИО"
              outlined
              hide-detaild
              type="text"
              value={ this.pass.human }
              onInput={ (value: string) => { this.pass = { ...this.pass, human: value } } }
            />
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-show={ this.pass.passTypeId === 1 }
                  dense
                  color="secondary"
                  label="Марка"
                  outlined
                  hide-detaild
                  type="text"
                  value={ this.pass.carModel }
                  onInput={ (value: string) => { this.pass = { ...this.pass, carModel: value } } }
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-show={ this.pass.passTypeId === 1 }
                  dense
                  color="secondary"
                  label="Номер"
                  outlined
                  hide-detaild
                  type="text"
                  value={ this.pass.regNumber }
                  onInput={ (value: number[]) => { this.pass = { ...this.pass, regNumber: value } } }
                />
              </v-col>
            </v-row>
            <v-checkbox
              v-show={ localStorage.getItem('securityTab') === 'tab-permanent-pass' }
              label="Бессрочный"
              value={ this.pass.isUnlimited }
              onChange={ (value: number[]) => { this.pass = { ...this.pass, isUnlimited: value } } }
            />
            {/* @ts-ignore */}
            <AppDatePicker
              value={ this.pass.date }
              onInput={ (value: number[]) => { this.pass = { ...this.pass, date: +value / 1000 } } }
              label="Действует до"
              placeholder={ localStorage.getItem('securityTab') === 'tab-permanent-pass' ? 'Действует до' : 'Дата прибытия' }
              allowed-date="allowedDates"
              min={ new Date() }
              class="mt-6"
              ref="picker"
              date-time-class="date-picker__pass"
              disabled={ this.pass?.isUnlimited ? true : null }
            />
            <v-text-field
              dense
              color="secondary"
              label="Комментарий"
              outlined
              hide-detaild
              type="text"
              value={ this.pass.comment }
              onInput={ (value: string) => { this.pass = { ...this.pass, comment: value } } }
            />
            <v-btn type="submit" onClick={ this.createPass } disabled={ this.passDate }>
              Отправить
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-tabs value={this.tab} align-with-title onChange={ (tab: string) => { localStorage.setItem('securityTab', tab) } }>
        {
          this.tabs.map((tab) => {
            return <v-tab link={ true } href={ '#' + tab.value }>
              { tab.title }
            </v-tab>
          })
        }
        <v-tab-item value='tab-pass' transition='false'>
          <div class="pa-3">
            <v-row justify="space-between">
              <v-col cols="auto">
                <div class="margin-every">
                  <v-btn onClick={ this.toggleSettingsView } color="green" class="bg-small" fab x-small tile>
                    <v-icon> { `mdi-${this.viewSettings ? 'minus' : 'plus'}` } </v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    onClick={ this.openCreatePassModal }
                  >
                    <v-icon left> mdi-plus </v-icon>
                      Добавить
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="6" lg="3">
                <v-text-field
                  dense
                  color="secondary"
                  onInput={ (value: string) => { this.searchField = value } }
                  label="Поиск..."
                  outlined
                  hide-detaild
                  type="text"
                />
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-row v-show={ this.viewSettings }>
                <v-col cols="3">
                  <AppFilterSelector
                    label='Управляющая организация'
                    dense
                    hide-details
                    options={ this.user?.tenants }
                    value={ this.query.tenantId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, tenantId: value } } }
                  />
                  <AppFilterSelector
                    label='Группа объектов'
                    url='buildingGroups'
                    ref='filter-buildingGroupId'
                    dense
                    hide-details
                    disabled={ !this.query.tenantId }
                    options={ this.cachedValues.buildingGroupId }
                    value={ this.query.buildingGroupId }
                    query={ { tenantId: this.query.tenantId } }
                    onInput={ (value: number[]) => { this.query = { ...this.query, buildingGroupId: value } } }
                  />
                  <AppFilterSelector
                    label='Квартал'
                    url='buildingsubgroups'
                    dense
                    hasSearch
                    hide-details
                    ref='filter-buildingSubGroupId'
                    disabled={ !this.query.tenantId }
                    query={ { tenantId: this.query.tenantId } }
                    options={ this.cachedValues.buildingSubGroupId }
                    value={ this.query.buildingSubGroupId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, buildingSubGroupId: value } } }
                  />
                  <AppFilterSelector
                    label='Объект'
                    url='buildings'
                    dense
                    chips
                    multiple
                    hide-details
                    disabled={ !this.query.tenantId }
                    value={ this.query.buildingId }
                    query={ { tenantId: this.query.tenantId } }
                    ref='filter-buildingSubGroupId'
                    options={ this.cachedValues.buildingSubGroupId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, buildingId: value } } }
                  />
                  <AppFilterSelector
                    label='Помещение'
                    url='places'
                    ref='filter-buildingId'
                    hide-details
                    dense
                    disabled={ !this.query.tenantId }
                    options={ this.cachedValues.buildingId }
                    query={ { buildingIds: this.query.buildingId || '' } }
                    value={ this.query.placeId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, placeId: value } } }
                  />
                </v-col>
                <v-col cols="3">
                  <AppFilterSelector
                    label='Клиент'
                    url='customers'
                    full-width
                    no-filter
                    dense
                    hide-details
                    ref='filter-customerId'
                    disabled={ !this.query.tenantId }
                    query={ { tenantId: this.query.tenantId } }
                    options={ this.cachedValues.customerId }
                    value={ this.query.customerId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, customerId: value } } }
                  />
                  <AppFilterSelector
                    label='Статус'
                    url='pass-statuses'
                    ref='filter-passStatusId'
                    options={ this.cachedValues.passStatusId }
                    dense
                    chips
                    multiple
                    hide-details
                    value={ this.query.passStatusId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, passStatusId: value } } }
                  />
                  <AppFilterSelector
                    label="Прибытие"
                    ref="filter-datesId"
                    options={ this.dict.date }
                    dense
                    hide-details
                    value={ this.query.datesId }
                    onInput={ (value: string[]) => { this.query = { ...this.query, date: value } } }
                  />
                  <AppFilterSelector
                    label="Уровень доступа"
                    ref="filter-passAccessLevelId"
                    options={ this.dict.passAccessLevel }
                    dense
                    chips
                    multiple
                    hide-detaild
                    value={ this.query.passAccessLevelId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, passAccessLevelId: value } } }
                  />
                </v-col>
                <v-col cols="3">
                  <AppFilterSelector
                    label="Тип"
                    url="pass-types"
                    ref="filter-passTypeId"
                    options={ this.cachedValues.passTypeId }
                    dense
                    chips
                    multiple
                    hide-details
                    value={ this.query.passTypeId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, passTypeId: value } } }
                  />
                  <AppFilterSelector
                    label="Тип автом."
                    url="car-types"
                    ref="filter-carTypeId"
                    options={ this.cachedValues.carTypeId }
                    dense
                    chips
                    multiple
                    hide-details
                    value={ this.query.carTypeId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, carTypeId: value } } }
                  />
                  <DatePicker
                    label={ 'Создано' }
                    name="createdAt"
                    dense
                    outlined
                    hide-details
                    click={ 'clear' }
                    value={ this.query.createdAt }
                    onInput={ (value: string[]) => { this.$set(this.query, 'createdAt', value) }}
                  />
                  <DatePicker
                    label={ 'Обновлено' }
                    name="updatedAt"
                    dense
                    outlined
                    hide-details
                    click={ 'clear' }
                    value={ this.query.updatedAt }
                    onInput={ (value: string[]) => { this.$set(this.query, 'updatedAt', value) }}
                  />
                </v-col>
                { Object.values(this.query).length ? <v-col cols="auto" class="mb-3">
                  <v-btn
                    color="primary"
                    onClick={ () => { this.query = {} } }
                  >
                    Очистить
                  </v-btn>
                </v-col> : null }
              </v-row>
            </v-expand-transition>
            <SecurityTable
              search={ this.searchField }
              query={ this.finalQuery }
            />
          </div>
        </v-tab-item>
        <v-tab-item value='tab-permanent-pass' transition='false'>
          <div class="pa-3">
            <v-row justify="space-between">
              <v-col cols="auto">
                <div class="margin-every">
                  <v-btn onClick={ this.toggleSettingsView } color="green" class="bg-small" fab x-small tile>
                    <v-icon> { `mdi-${this.viewSettings ? 'minus' : 'plus'}` } </v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    onClick={ () => { this.viewCreatePassModal = true } }
                  >
                    <v-icon left> mdi-plus </v-icon>
                      Добавить
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="6" lg="3">
                <v-text-field
                  dense
                  color="secondary"
                  onInput={ (value: string) => { this.searchField = value } }
                  label="Поиск..."
                  outlined
                  hide-detaild
                  type="text"
                />
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-row v-show={ this.viewSettings }>
                <v-col cols="3">
                  <AppFilterSelector
                    label='Управляющая организация'
                    dense
                    hide-details
                    options={ this.user?.tenants }
                    value={ this.query.tenantId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, tenantId: value } } }
                  />
                  <AppFilterSelector
                    label='Группа объектов'
                    url='buildingGroups'
                    ref='filter-buildingGroupId'
                    dense
                    hide-details
                    disabled={ !this.query.tenantId }
                    options={ this.cachedValues.buildingGroupId }
                    value={ this.query.buildingGroupId }
                    query={ { tenantId: this.query.tenantId } }
                    onInput={ (value: number[]) => { this.query = { ...this.query, buildingGroupId: value } } }
                  />
                  <AppFilterSelector
                    label='Квартал'
                    url='buildingsubgroups'
                    dense
                    hasSearch
                    hide-details
                    ref='filter-buildingSubGroupId'
                    disabled={ !this.query.tenantId }
                    query={ { tenantId: this.query.tenantId } }
                    options={ this.cachedValues.buildingSubGroupId }
                    value={ this.query.buildingSubGroupId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, buildingSubGroupId: value } } }
                  />
                  <AppFilterSelector
                    label='Объект'
                    url='buildings'
                    dense
                    chips
                    multiple
                    hide-details
                    disabled={ !this.query.tenantId }
                    value={ this.query.buildingId }
                    query={ { tenantId: this.query.tenantId } }
                    ref='filter-buildingSubGroupId'
                    options={ this.cachedValues.buildingSubGroupId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, buildingId: value } } }
                  />
                  <AppFilterSelector
                    label='Помещение'
                    url='places'
                    ref='filter-buildingId'
                    hide-details
                    dense
                    disabled={ !this.query.tenantId }
                    options={ this.cachedValues.buildingId }
                    query={ { buildingIds: this.query.buildingId || '' } }
                    value={ this.query.placeId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, placeId: value } } }
                  />
                </v-col>
                <v-col cols="3">
                  <AppFilterSelector
                    label='Клиент'
                    url='customers'
                    full-width
                    no-filter
                    dense
                    hide-details
                    ref='filter-customerId'
                    disabled={ !this.query.tenantId }
                    query={ { tenantId: this.query.tenantId } }
                    options={ this.cachedValues.customerId }
                    value={ this.query.customerId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, customerId: value } } }
                  />
                  <AppFilterSelector
                    label='Статус'
                    url='permanent-pass-statuses'
                    ref='filter-passStatusId'
                    options={ this.cachedValues.permanentPassStatusId }
                    dense
                    chips
                    multiple
                    hide-details
                    value={ this.query.permanentPassStatusId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, permanentPassStatusId: value } } }
                  />
                  <AppFilterSelector
                    label="Тип"
                    url="pass-types"
                    ref="filter-passTypeId"
                    options={ this.cachedValues.passTypeId }
                    dense
                    chips
                    multiple
                    hide-details
                    value={ this.query.passTypeId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, passTypeId: value } } }
                  />
                </v-col>
                <v-col cols="3">
                  <AppFilterSelector
                    label="Тип автом."
                    url="car-types"
                    ref="filter-carTypeId"
                    options={ this.cachedValues.carTypeId }
                    dense
                    chips
                    multiple
                    hide-details
                    value={ this.query.carTypeId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, carTypeId: value } } }
                  />
                  <DatePicker
                    label={ 'Создано' }
                    name="createdAt"
                    dense
                    outlined
                    hide-details
                    value={ this.query.createdAt }
                    onInput={ (value: string[]) => { this.$set(this.query, 'createdAt', value) }}
                  />
                  <DatePicker
                    label={ 'Обновлено' }
                    name="updatedAt"
                    dense
                    outlined
                    hide-details
                    value={ this.query.updatedAt }
                    onInput={ (value: string[]) => { this.$set(this.query, 'updatedAt', value) }}
                  />
                </v-col>
                { Object.values(this.query).length ? <v-col cols="auto" class="mb-3">
                  <v-btn
                    color="primary"
                    onClick={ () => { this.query = {} } }
                  >
                    Очистить
                  </v-btn>
                </v-col> : null }
              </v-row>
            </v-expand-transition>
            <SecurityTable
              search={ this.searchField }
              query={ this.finalQuery }
              isAll={ false }
            />
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
  }
}
