
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class AppIcon extends Vue {
  @Prop({ type: String, default: 'love' }) name!: string;

  colorDark = 'var(--primary-dark-1)';
  colorDarker = 'var(--primary-dark-2)';
  colorDarkest = 'var(--primary-dark-3)';
}
