
import dayjs from 'dayjs'
import { Component, Vue, Prop } from 'vue-property-decorator'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import ServiceBlock from '@/components/general/ServiceBlock.vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import FormParserCatalogItem from '@/components/general/FormParserCatalogItem.vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import AppPhoneField from '@/components/general/AppPhoneField.vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import AppDatePicker from '@/components/app/AppDatePicker.vue'

import { PageModule } from '@/store/page'
// import { UserModule } from '@/store/user'
import { EventLayout } from '@/models/index'
import { FormElement, Datetime, ImageFormObject, ComputedServiceForm } from '@/models/service'
import { UserModule } from '@/store/user'
import { HandleError } from '@/utils/helper'

interface FormDatetimes {
  times: Array<Datetime>;
  time: number;
  daytime: number;
}

interface FormDatetimesMonth {
  el: any;
  offset: number;
  width: number;
}

type TFormDatetimes = Record<string, Array<FormDatetimes> | undefined>

@Component({
  components: {
    ServiceBlock,
    AppPhoneField,
    AppDatePicker,
    FormParserCatalogItem
  }
})
export default class FormParser extends Vue {
  finalCost = 0

  @Prop({ type: Array, required: true }) form!: Array<FormElement>
  @Prop({ type: Function, required: true }) callbackForm!: (form: ComputedServiceForm) => void
  @Prop({ type: Boolean }) disabled!: boolean
  @Prop({ type: Boolean }) disableButton!: boolean
  @Prop({ type: Boolean, default: false }) showPrice!: boolean
  @Prop({ type: Boolean, default: true }) canSelectPastDate!: boolean
  @Prop({ type: Number, required: true }) serviceId!: number
  @Prop({ type: Number, required: true }) tenantId!: number

  imageModal = {
    view: false,
    src: ''
  }

  catalogModal = {
    view: false,
    element: {},
    item: {},
    index: ''
  }

  dayjs = dayjs
  mountedForm = false
  isLoading = false

  swiperOption = {
    slidesPerView: 'auto',
    spaceBetween: 10,
    mousewheel: true
  }

  swiperDateOption = {
    freeMode: true,
    freeModeMomentum: false,
    centerInsufficientSlides: true,
    slidesPerView: 'auto',
    spaceBetween: 10
  }

  menu = false
  valid = true
  datetimes: TFormDatetimes = {}
  months: Array<FormDatetimesMonth> = []

  get isDark() { return PageModule.isDark }

  get filteredForm() {
    return this.form
      .map(element => {
        if (element.type === 'date') {
          element.value = new Date().toISOString().substr(0, 10)
        } else if (element.type === 'select' && !element.value) {
          element.value = element.values[0].value
        } else if (['text', 'string'].includes(element.type)) {
          element.value = element.value || ''
        } else if (element.type === 'switch' && element.value === '') {
          element.value = 0
        }

        return element
      })
      .filter((element: FormElement) => {
        return this.showElement(element.showIfElementId, element.showIfElementValue, element)
      })
  }

  get apiForm() {
    const handleArr = (array: any) => array.map(({ id, type, value, values, time, times, date, text, items }: any) => {
      const obj: { id: number; value: string | number | any[]; text?: string } = { id, value }

      if (type === 'autocomplete') {
        obj.text = items[0]?.text
      }

      if (values?.length && ['image', 'catalog'].includes(type)) {
        obj.value = handleArr(values)
      }

      if (type === 'image') {
        obj.value = values.map(({ value }: any) => value)
      }

      if (type === 'date') {
        if (typeof obj.value === 'string') {
          obj.value = new Date(obj.value).getTime() / 1000
        }
        obj.value = '' + obj.value
      }

      if (type === 'catalog') {
        obj.value = values.filter(({ value }: any) => value)
      }

      const hasDatetimeValue = (time && date) || typeof date === 'object'
      const hasTimeSlider = times && times.length

      if (type === 'datetime' && !hasTimeSlider && hasDatetimeValue) {
        if (typeof date !== 'object') {
          const paternDate = `${date}-${time.replace(':', '-')}` // 2020-07-09-01-02
            .split('-')
            .map(t => parseInt(t)) // [2020, 07, 09, 01, 02]

          paternDate[1]-- // js Date minus month

          const finalDate = new Date(...paternDate as [number, number, number, number, number])

          obj.value = Math.ceil(finalDate.getTime() / 1000)
        } else {
          obj.value = Math.ceil(date.getTime() / 1000)
        }
      }

      return obj
    }) as ComputedServiceForm

    return handleArr(this.form)
  }

  get isActiveCheckbox() {
    return (element: FormElement, item: any) => {
      let value: string | string[] = element.value as string

      if (!value || !value.includes) { return false }

      value = value.split(', ')

      return value.includes(item.value + '') || item.selected
    }
  }

  openModalImage(image: string) {
    this.imageModal.view = true
    this.imageModal.src = image
  }

  getPrice({ companyPricePrice: price, companyPriceCurrencySign: sign }: FormElement) {
    return price ? `${price} ${sign}` : ''
  }

  formatSelectorText(element: FormElement) {
    if (element.companyPricePrice) {
      return `${element.title} ${element.companyPricePrice ? '- ' + element.companyPricePrice + element.companyPriceCurrencySign : ''}`
    }

    return element.title
  }

  changeSelection(element: FormElement, event: any) {
    element.value = event
  }

  updateCheckbox(element: FormElement, child: FormElement, value: boolean) {
    let array = (element.value as string || '').split(', ').filter(Boolean)

    if (value) {
      array.push(child.value as string)
    } else {
      array = array.filter((value: string) => parseInt(value) !== child.value)
    }

    const finalValue = array.join(', ')

    element.value = finalValue
  }

  formatDate(date: string | number) {
    if (typeof date === 'string') {
      return date.split('-').reverse().join('.')
    } else {
      return dayjs(date * 1000).format('DD.MM.YYYY')
    }
  }

  getElement(id: number | string) {
    return this.form.find((element: any) => element.id === id)
  }

  showElement(id: number | string, value: number | string | boolean, formElement?: FormElement) {
    const element = this.getElement(id || -1)
    const booleanElementsValue = ['switch']

    if (!element || !id) { return true }

    let isShow = element.value + '' === value + '' || (booleanElementsValue.includes(element.type) && Boolean(value) === element.value)

    if (element.type === 'checkbox') {
      const valueInput = element.value || (element.values as FormElement[]).filter(({ selected }) => selected).map(({ value }) => value).join(' ,')

      isShow = (valueInput as string).includes(value + '')
    }

    if (isShow === false && formElement && ['radio', 'checkbox', 'switch'].includes(formElement.type)) {
      formElement.value = '' // Удалить значения, если сктыры элементы
    }

    return isShow
  }

  getValue({ id }: FormElement) {
    const element = this.getElement(id) as FormElement

    return element.values.length ? element.values : element.value
  }

  isRequired(element: FormElement) {
    return element.required && this.showElement(element.showIfElementId, element.showIfElementValue)
  }

  required(element: FormElement) {
    return (value: string) => {
      if (this.isRequired(element)) {
        return !!value || 'Обязательное поле'
      }
      return true
    }
  }

  getImages(id: number) {
    return [{ isLabel: true }, ...(this.getElement(id)?.values || [])]
  }

  deleteImage(id: number, index: number) {
    const element = this.getElement(id)

    if (element) {
      element.values.splice(index - 1, 1)
    }
  }

  timeToUnix(time: string) {
    if (time) {
      const [hours, minutes] = time.split(':').map(parseFloat)

      return (hours * 60 * 60) + minutes * 60
    }
    return 0
  }

  handleDateChange(element: FormElement, $event: string) {
    this.$set(element, 'date', $event)
  }

  handleTimeChange(element: FormElement, $event: string) {
    this.$set(element, 'time', $event)
  }

  async setDatetime(element: FormElement, datetime: Datetime) {
    const items = await PageModule.updateTimestamp({ id: this.serviceId, date: datetime.timestamp, tenantId: this.tenantId })

    this.$set(element, 'timestamp', datetime.timestamp)

    this.$set(element, 'times', items.map((item: any) => {
      const time = new Date(item.timestamp * 1000)
      const hours = ('00' + time.getHours()).slice(-2)
      const minutes = ('00' + time.getMinutes()).slice(-2)

      return {
        title: `${hours}:${minutes}`,
        disabled: !item.isAvailable,
        timestamp: item.timestamp
      }
    }))
  }

  setDatetimeValue(element: FormElement, timestamp: number) {
    this.$set(element, 'value', timestamp)
  }

  getDatetimes(id: number) {
    const element = this.getElement(id)
    const dayOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
    const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
    let finalArray: Array<Datetime> = []

    if (element?.availableDates) {
      finalArray = element.availableDates
        .map((item, idx) => {
          const date = new Date(item.timestamp * 1000)
          const day = dayOfWeek[date.getDay()]
          const dayNumeric = date.getDate()
          const month = dayNumeric === 1 || idx === 0
            ? `${months[date.getMonth()]} ${date.getFullYear()}`
            : null
          return {
            day,
            dayNumeric,
            timestamp: item.timestamp,
            disabled: !item.isAvailable,
            month
          }
        })
    }

    return finalArray
  }

  async updateImage(event: EventLayout<HTMLInputElement>, id: number) {
    const element = this.getElement(id)

    if (!element) return

    const length = element.values.length
    const files = await Promise.all<ImageFormObject>(
      Array.from(event.target.files as ArrayLike<File>)
        .map((file: File, index): Promise<ImageFormObject> => {
          return new Promise((resolve) => {
            const { type } = file
            const reader = new FileReader()

            const extentions = ['image/jpeg', 'image/png']
            if (!extentions.includes(type)) {
              return PageModule.SEND_NOTIFICATION({ type: 'error', message: 'Неверный формат изображения' })
            }

            reader.onload = (e) => {
              UserModule.uploadFile((event.target.files as FileList)[index])
                .then((response) => {
                  (element.values[length + index] as ImageFormObject).loading = false;
                  (element.values[length + index] as ImageFormObject).value = response.items[0].name || ''
                })
              return resolve({
                src: reader.result as string || '',
                loading: true,
                value: ''
              })
            }

            reader.readAsDataURL(file)
          })
        })
    )

    const input = this.$refs[id] as any

    if (input) {
      input[0].value = []
    }

    element.values = element?.values?.concat(files)
  }

  async handleForm() {
    const validation = this.filteredForm.filter((element) => {
      if (!element.required) return false

      if (element.type === 'catalog') {
        if (!element?.values?.some(item => item.value)) {
          this.$set(this.getElement(element.id) as FormElement, 'error', `Поле "${element.title}" обязательно для заполнения`)
          return true
        } else {
          this.$set(this.getElement(element.id) as FormElement, 'error', '')
        }
      }

      if (element.type === 'quantity' && !element.value) {
        return true
      }

      if (element.type === 'image' && !element?.values?.length) {
        this.$set(this.getElement(element.id) as FormElement, 'error', 'Необходимо добавить изображение')
        return true
      }

      return false
    })

    if (!(this.$refs.form as any).validate() || validation.length) {
      return
    }

    const formData = this.apiForm
    this.isLoading = true

    try {
      await this.callbackForm(formData)
    } catch (error) {
      HandleError(error)
    } finally {
      this.isLoading = false
    }
  }

  mounted() {
    this.form.forEach(element => {
      if (element.type === 'catalog') {
        element.values.forEach((item: any) => {
          item.value = 0
        })
      }
      if (['panel', 'radio'].includes(element.type)) {
        const selected = (element.values as FormElement[]).find(radio => radio.selected)
        if (selected && selected.value) {
          element.value = selected.value
        }
      }
    })

    const monthRefs: any = this.$refs.month || []
    if (monthRefs.length) {
      const minOffset = Math.min(...(monthRefs as any).map((el: any) => el.getBoundingClientRect().x))
      this.months = (monthRefs as any)
        .map((el: any) => ({
          el,
          offset: el.getBoundingClientRect().x - minOffset,
          width: el.getBoundingClientRect().width
        }))
        .sort((a: FormDatetimesMonth, b: FormDatetimesMonth) => (a.offset > b.offset) ? 1 : -1)
    }
    this.mountedForm = true
  }

  updated() {
    this.mountedForm = true
  }

  datetimeTranslate(x: number) {
    for (const idx in this.months) {
      this.months[idx].el.style.left = ''
    }

    const active: number = this.months.findIndex((month, idx) => {
      return month.offset + x < 0 && (!this.months[idx + 1] || this.months[idx + 1].offset + x > 0)
    }) || 0

    if (!this.months[active]) return

    let left = Math.abs(x) - this.months[active].offset
    const margin = 16

    if (this.months[active + 1]) {
      if (left + this.months[active].width + this.months[active].offset > this.months[active + 1].offset - margin) {
        left = Math.abs(this.months[active].width + this.months[active].offset - this.months[active + 1].offset) - margin
      }
    }

    this.months[active].el.style.transform = `translateX(${left}px)`
  }

  showCatalogItem({ elementIndex, index }: { elementIndex: number; index: string }) {
    this.catalogModal = {
      view: true,
      element: this.filteredForm[elementIndex],
      item: this.filteredForm[elementIndex].values[parseInt(index)],
      index
    }
  }
}
