import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SelectionField extends Vue {
  @Prop({ type: String, default: '' }) selection!: string
  @Prop({ type: String, default: '' }) text!: string

  get regExp() {
    return new RegExp(this.parsedSearch, 'gi')
  }

  get parsedSearch() {
    return '(' + this.selection.toString().trim().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') + ')'
  }

  get parsedMsg() {
    return (this.text ? this.text : '').toString().split(this.regExp)
  }

  render() {
    return <span>
      {
        this.parsedMsg.map((message, i) => {
          if (i % 2 === 0) {
            return message
          } else if (message) {
            return <span key={i} class="selection-field"> { message } </span>
          }
        })
      }
    </span>
  }
}
