import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import OrdersPage from '@/views/orders'
import SecurityPage from '@/views/security'
import LoginPage from '@/views/login'
import ResetPage from '@/views/reset'
import NotFoundPage from '@/views/notFoundPage'
import { PageModule } from '@/store/page'
import ErrorPage from '@/views/error'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {
      headerTitle: 'Login page',
      layout: 'none'
    }
  },
  {
    path: '/reset',
    name: 'resetPassword',
    component: ResetPage,
    meta: {
      headerTitle: 'Reset Password',
      layout: 'none'
    }
  },
  {
    path: '/',
    name: 'home',
    component: OrdersPage
  },
  {
    path: '/orders',
    name: 'orders',
    component: OrdersPage
  },
  {
    path: '/security',
    name: 'security',
    component: SecurityPage
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage
  },
  {
    path: '*',
    name: 'undefined',
    component: NotFoundPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async(to: Route, from: Route, next: NavigationGuardNext) => {
  const token = localStorage.getItem('token')
  const dontNeedTokenPages = ['login', 'error', 'resetPassword']

  if (!dontNeedTokenPages.includes(to.name || '') && !PageModule.isInited) {
    try {
      await PageModule.initPage()
    } catch (error) {
      if (to.name === 'error') {
        return next()
      }
      if (token) {
        return next('/error')
      } else {
        return next('/login')
      }
    }
  }

  if (!token && !dontNeedTokenPages.includes(to.name || '')) {
    return next('/login')
  }

  return next()
})

router.afterEach((to: Route, from: Route) => {
  const routes = PageModule.routes.slice(-9)

  routes.push(to)
  PageModule.SET_STATE_PAGE({ key: 'routes', value: routes })
})

export default router
