import { Component, Vue } from 'vue-property-decorator'
import { WebsocketData, WebsocketEvent, WebsocketPayload } from '@/models'
import io from 'socket.io-client'

const ioUrl = process.env.VUE_APP_IO_URL || 'wss://websocket.domyland.ru/socket.io/'
const socket = io(ioUrl, { secure: true, transports: ['websocket', 'polling'] })
const hideEvents = [WebsocketEvent.CONNECT, WebsocketEvent.AUTH_REQUEST, WebsocketEvent.AUTH_RESPONSE]
const eventNames = Object.values(WebsocketEvent).filter((value: WebsocketEvent) => !hideEvents.includes(value))

@Component
export default class AppFilterSelector extends Vue {
  mounted() {
    this.connect()
  }

  connect() {
    if (!socket) {
      setTimeout(this.connect, 5000)
      return
    }

    socket.on('connect', () => {
      const token = localStorage.getItem('token') || null

      socket.emit('authorization_req', { token })
      socket.on('authorization_res', (data: WebsocketData) => {
        const statusMessage = `socket connection: ${data.status}`

        if (data.statusCode === 200) {
          console.log({ statusMessage })
        } else {
          console.warn(statusMessage)
          this.reconnect(5000)
          return ''
        }
      })

      eventNames.forEach((name) => {
        socket.on(name, (data: WebsocketData) => {
          this.$socket.next({ name, data })
        })
      })

      socket.on('connect_error', (event: WebsocketData) => {
        console.log('Connection Error:\n', event)
        this.reconnect(5000)
      })

      socket.on('error', (event: WebsocketPayload) => {
        console.log('Socket.io reported a generic error:\n', event)
        this.reconnect(5000)
      })
    })
  }

  reconnect(timeout = 1000) {
    console.log('reconnect')
    if (socket?.connected) {
      socket.disconnect()
    }
    setTimeout(this.connect, timeout)
  }
}
