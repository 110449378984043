import { Component, Vue } from 'vue-property-decorator'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import AppIcon from '@/components/app/AppIcon.vue'
import TheHeaderMenu from '@/components/general/TheHeaderMenu'

import { CreateElement } from 'vue/types/umd'
import { UserModule } from '@/store/user'

@Component({
  components: {
    AppIcon,
    TheHeaderMenu
  }
})
export default class TheBar extends Vue {
  get user() {
    return UserModule.user
  }

  render(h: CreateElement) {
    return <v-app-bar
      id="app-bar"
      floating
      app
      clipped-left
      clipped-right
      dense
      color="primary"
      flat
    >
      <v-row justify="space-between" align="center" class="w-100">
        <v-col cols="auto" class="d-flex ai-center" style="max-width: 170px">
          <AppIcon name="logo" class="header-logo"/>
          <h2 class="header-title mt-2 ml-3"> сall center </h2>
        </v-col>
        <v-col cols="auto" class="d-flex">
          { h('v-menu',
            {
              props: {
                bottom: true,
                right: true,
                'offset-y': true,
                'min-width': '200'
              },
              scopedSlots: {
                activator: ({ on, attrs }) => h('h3', {
                  on,
                  props: { ...attrs },
                  class: 'header-title c-pointer mt-1'
                },
                'мои организации'
                )
              }
            },
            [
              <v-list>
                {
                  this.user?.tenants.map(tenant => {
                    return <v-list-item link={ true } target='_blank' href={ tenant.url } key={ tenant.id }>
                      <v-list-item-title>{ tenant.title }</v-list-item-title>
                    </v-list-item>
                  })
                }
              </v-list>
            ]
          ) }
          <TheHeaderMenu />
        </v-col>
      </v-row>
    </v-app-bar>
  }
}
