import { Component, Vue } from 'vue-property-decorator'
import { PageModule } from '@/store/page'
import OrdersTabs from '@/components/page/orders/OrdersTabs'

@Component({
  components: {
    OrdersTabs
  }
})
export default class OrdersPage extends Vue {
  render() {
    return <div class="index pa-3">
      <OrdersTabs />
    </div>
  }
}
