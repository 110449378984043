
import { Component, Vue, Prop } from 'vue-property-decorator'

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { mask } from 'vue-the-mask'

@Component({
  directives: {
    mask
  }
})
export default class AppPhoneInput extends Vue {
  @Prop({ type: String }) value!: string

  initValue = ''

  onFocus(): void {
    if (this.value.trim() === '') {
      this.$emit('input', '+7 ')
      this.initValue = '+7 '
      const phoneInput = (this.$refs.phoneInput as any).$el.querySelector('input')
      setTimeout(() => {
        if (phoneInput.selectionStart === 0) {
          phoneInput.setSelectionRange(3, 3)
        }
      }, 100)
    }
  }

  onBlur(): void {
    if (this.value.trim() === '+') {
      this.initValue = ''
      this.$emit('input', '')
    }
  }

  onInput(event: string): void {
    if (this.initValue === '+7 ') {
      this.initValue = event
    }
    this.$emit('input', event)
  }
}
