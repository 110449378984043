import { Component, Vue } from 'vue-property-decorator'
import { ScopedSlot } from 'vue/types/vnode'
import AppFilterSelector from '@/components/app/AppFilterSelector'
import { FormatSelectorItem, InputSelectorItem } from '@/models'

@Component({
  extends: AppFilterSelector
})
export default class AppFilterSelectorPlaces extends Vue {
  formatItem(item: InputSelectorItem): FormatSelectorItem {
    return {
      text: item.address || item.title,
      value: Number(item.id) > 0 ? Number(item.id) : item.id,
      accounts: item.accounts,
      placeTypeTitle: item.placeTypeTitle
    }
  }

  buildSelectionItem(): ScopedSlot | undefined {
    return ({ item, on }: { item: FormatSelectorItem; on: Record<string, Function> }) => {
      if (item.value <= 0) {
        return <v-list-item { ...{ on }}>
          <v-list-item-content>
            <v-list-item-title class="ws-normal">{ item.text } </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      }

      const accounts = (item.accounts || [])
        .map((account) => (!account.number.includes('л/с №') ? `ЛС ${account.number}` : item.number))
        .join('; ')

      return <v-list-item { ...{ on }}>
        <v-list-item-content>
          <v-list-item-title class="ws-normal">{ item.text } </v-list-item-title>
          <v-list-item-subtitle class="ws-normal">{ item.placeTypeTitle } { item.placeTypeTitle && accounts.length > 0 && ', ' } { accounts } </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    }
  }
}
