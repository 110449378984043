import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class OrderRating extends Vue {
  @Prop({ type: Number, default: 0 }) value!: number
  @Prop({ type: Boolean, default: false }) changeable!: number
  @Prop({ type: Boolean, default: true }) clearable!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: String, default: '☆' }) emptyStar!: number

  maxRating = 5
  filledStar = '★'

  get orangeStars() {
    return Array.from(Array(this.value)).map(_ => this.filledStar)
  }

  get silverStars() {
    return Array.from(Array(this.maxRating - this.value)).map(_ => this.emptyStar)
  }

  get spanClass() {
    return {
      'cursor-pointer': this.changeable && !this.disabled
    }
  }

  emitValue(rate: number) {
    if (this.disabled) {
      return
    }
    if (rate === this.value && this.clearable) {
      rate = 0
    }

    this.$nextTick(() => this.$emit('input', rate))
  }

  render() {
    if ((!(this.value > 0) || this.changeable)) {
      return null
    }

    return <div style="white-space:nowrap;font-size:0.8rem">
      {
        this.orangeStars.map((rate, index) => {
          return <span
            key={ `orange-${index}` }
            class={ this.spanClass }
            style="color: orange"
            onClick={ () => this.emitValue(index + 1) }
          > { rate } </span>
        })
      }
      {
        this.silverStars.map((rate, index) => {
          return <span
            key={ `silver-${index}` }
            class={ this.spanClass }
            style="color: silver"
            onClick={ () => this.emitValue(this.value + index + 1) }
          > { rate } </span>
        })
      }
    </div>
  }
}
