import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'
import store from '@/store/index'
import { Route } from 'vue-router'
import { $get, $post } from '@/plugins/axios'
import { OrderItem } from '@/models/page'
import IterableRespose, { InputSelectorItem } from '@/models'
import uniqBy from 'lodash/uniqBy'
import { GeneralForm, PostOrderData, PostServicesIdAvalibleTime } from '@/models/service'
import { UserModule } from './user'

export type TAlertType = 'success' | 'error' | 'warning' | 'info';

export interface Alert {
  type: TAlertType;
  message: string;
  show: boolean;
}

@Module({ dynamic: true, store, name: 'page' })
class Page extends VuexModule {
  allOrders: IterableRespose<OrderItem> = {
    items: [],
    nextRow: 0
  }

  newOrders: IterableRespose<OrderItem> = {
    items: [],
    nextRow: 0
  }

  alert: Alert = {
    type: 'success',
    message: '',
    show: false
  }

  primaryColor = ''
  theme = 'light'
  isInited = false
  routes: Route[] = []

  get route(): Route | undefined {
    return this.routes.slice(-1)[0]
  }

  get layout() {
    return this.route?.meta?.layout || 'default'
  }

  get isDark() {
    return this.theme === 'dark'
  }

  @Mutation
  SET_STATE_PAGE<S extends this, K extends keyof this>({ key, value }: { key: K; value: S[K] }) {
    this[key] = value
  }

  @Mutation
  LOAD_MORE<S extends 'allOrders' | 'newOrders'>({ key, items, nextRow }: { key: S; items: any[]; nextRow: number }) {
    this[key] = {
      items: uniqBy([...this[key].items, ...items], 'id'),
      nextRow
    }
  }

  @Mutation
  SEND_NOTIFICATION({ type, message }: { type: TAlertType; message: string }) {
    this.alert = { type, message, show: true }
  }

  @Action
  async getOrders({ query, loadMore, isAll }: { query: Record<string, string>; loadMore?: boolean; isAll?: boolean } = { query: {}, loadMore: false }) {
    const key = isAll ? 'allOrders' : 'newOrders'
    const { nextRow, items } = await $get('/orders', { params: { ...query, fromRow: loadMore ? this[key].nextRow : 0 } })

    if (loadMore) {
      this.LOAD_MORE({ key, items, nextRow })
    } else {
      this.SET_STATE_PAGE({ key, value: { items, nextRow } })
    }
  }

  @Action
  async updateTimestamp({ id, date, tenantId }: { id: string | number; date: number; tenantId: string | number }) {
    const { items } = await $post<PostServicesIdAvalibleTime>(`/services/${id}/availableTime`, null, { params: { date, tenantId } })

    return items
  }

  @Action
  async initPage() {
    await UserModule.getUser()
  }
}

export const PageModule = getModule(Page)
