export default interface IterableRespose<T> {
  items: T[];
  nextRow: number;
}

export interface EventLayout<T = any> {
  target: T;
}

export interface FormatSelectorItem {
  value: number | string;
  text: string;
  color?: string;
  phone?: string;
  subTitle?: string;
  avatar?: string;
  image?: string;
  address?: string;
  accounts?: { id: number; number: string }[];
  placeTypeTitle?: string;
  number?: string;
  city?: string;
}

export interface InputSelectorItem {
  text: string;
  id: number | string;
  title: string;
  label?: string;
  name?: string;
  fullName?: string;
  color?: string;
  image?: string;
  customerImage?: string;
  address?: string;
  phoneNumber?: string;
  isCRM?: boolean;
  accounts?: { id: number; number: string }[];
  placeTypeTitle?: string;
  city?: string;
}

export type InputSelectorValue = number[] | number | string;

export enum WebsocketEvent {
  CONNECT='connect',
  AUTH_REQUEST='authorization_req',
  AUTH_RESPONSE='authorization_res',
  PASS_UPDATE='pass-update',
  PASS_NEW='pass-new',
  PERMANENT_PASS_UPDATE='permanent-pass-update',
  PERMANENT_PASS_NEW='permanent-pass-new',
  ORDER_NEW='order-new',
  CHAT_MESSAGE='chat-message',
  NEW_CALL='new-call',
  NEW_BACK_CALL='new-back-call',
  CALL_RESULT='call-result',
  NEW_MISSED_CALL='new-missed-call',
  FLUSH_CACHE='flush-cache',
  ORDER_STATUS_CHANGE='order-status-change',
  ORDER_COMMENT_NEW='order-comment-new',
  CRM_EXPORT_DOWNLOAD_LINK='crm-export-download-link'
}

export interface WebsocketPayload {
  readonly name: WebsocketEvent;
  readonly data: WebsocketData;
}

export interface WebsocketData {
  readonly file_url?: string;
  readonly id: number;
  readonly event: WebsocketEvent;
  readonly userId: number | null;
  readonly customerId: number | null | undefined;
  readonly tenantId: number;
  readonly token: number;
  readonly source: string;
  readonly status: string;
  readonly statusCode: number;
  readonly targetStatusId: number;
}
