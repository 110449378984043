import { PageModule } from '@/store/page'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ErrorPage extends Vue {
  isLoading = false

  async checkRequest() {
    try {
      if (this.isLoading) {
        return
      }

      this.isLoading = true

      await PageModule.initPage()

      const pastRoute = localStorage.getItem('pastErrorRoute') || ''
      if (!pastRoute) {
        if (this.$route.path === '/error') {
          this.$router.push('/')
        } else {
          this.$router.go(0)
        }
        return
      }

      this.$router.push(pastRoute)
    } finally {
      setTimeout(() => {
        this.isLoading = false
      }, 1500)
    }
  }

  render() {
    return (
      <div class="fullscreen fullscreen--login">
        <div class="block login-view">
          <p>
            Запрашиваемая страница не доступна
          </p>
          <div class="d-flex jc-center">
            <v-btn
              color="primary"
              depressed
              primary
              loading={ this.isLoading }
              onClick={ this.checkRequest }
            >
              Обновить
            </v-btn>
          </div>
        </div>
      </div>
    )
  }
}
