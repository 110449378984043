
import { Component, Vue, Prop } from 'vue-property-decorator'
import { PageModule } from '@/store/page'

@Component
export default class FormParserCatalogItem extends Vue {
  @Prop({ type: Object }) item!: any
  @Prop({ type: String }) price!: string
  @Prop({ type: Boolean }) fullDescription?: boolean

  get isDark() { return PageModule.isDark }

  handleClick() {
    if (this.item.enabled !== false) {
      this.$emit('click')
    }
  }
}
