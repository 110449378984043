
import { Vue, Component, Prop } from 'vue-property-decorator'

import { Service } from '@/models/service'

@Component
export default class ServiceBlock extends Vue {
  @Prop({ required: true }) service!: Service
  @Prop({ default: false }) pinned!: boolean
}
