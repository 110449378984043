import { Component, Vue } from 'vue-property-decorator'
import AppItem from '@/components/app/AppItem'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import AppIcon from '@/components/app/AppIcon.vue'

@Component({
  components: {
    AppItem,
    AppIcon
  }
})
export default class TheDrawer extends Vue {
  miniVariant = false

  get menuItems() {
    return [{
      badge: 0,
      icon: 'menu-services',
      id: 0,
      isAvailable: true,
      name: 'services',
      title: 'Обращения',
      items: [
        {
          badge: 0,
          id: 1,
          to: 'orders',
          icon: false,
          isAvailable: true,
          name: 'allorders',
          title: 'Все заявки'
        },
        {
          id: 2,
          to: 'security',
          icon: false,
          isAvailable: true,
          name: 'security',
          title: 'Охрана'
        }
      ]
    }]
  }

  render() {
    return <v-navigation-drawer
      id="core-navigation-drawer"
      app
      width="260"
      dark={ true }
      clipped
      mobile-breakpoint="960"
      mini-variant-width="80"
      mini-variant={ this.miniVariant }
    >
      <v-list expand nav>
        {
          this.menuItems.map((menuItem) => {
            return <div>
              {
                menuItem.items.map((item) => {
                  return <v-list-item
                    to={ item.to }
                    key={ item.id }
                    link
                  >
                    <v-list-item-title class="ml-1" domPropsInnerHTML={ item.title }></v-list-item-title>
                  </v-list-item>
                })
              }
            </div>
          })
        }
      </v-list>
    </v-navigation-drawer>
  }
}
