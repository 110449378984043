import { Component, Prop, Vue } from 'vue-property-decorator'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import AppIcon from '@/components/app/AppIcon.vue'
import { $get } from '@/plugins/axios'
import { AxiosError } from 'axios'
import { UserModule } from '@/store/user'

@Component({
  template: `
    <v-btn
      ref="button"
      fab
      tile
      small
      outlined
      :loading="loading"
      button-class="br-2"
      color="success"
      title="Экспорт"
      @click="exportToExcel"
    >
    <template
      #default
    >
      <app-icon name="excel-export" />
    </template>
  </v-btn>
`,
  components: { AppIcon }
})
export default class ExcelExportBackend extends Vue {
  @Prop({ type: String }) url!: string;
  @Prop({ type: Object, required: true }) query!: Record<string, string>

  timerToDownloadFile: ReturnType<typeof setTimeout> | null = null;
  fileLink: null | string = null;
  loading = false;

  async exportToExcel(): Promise<void> {
    this.loading = true
    await $get(`${this.url.replace(/^\/?api\//, '')}/export`, { params: { ...this.query } })
      .then(() => {
        return UserModule.SEND_NOTIFICATION({ type: 'success', message: 'Экспорт записей начат, пожалуйста подождите' })
      })
      .catch(error => {
        this.loading = false
        return UserModule.SEND_NOTIFICATION({ type: 'error', message: (error as AxiosError)?.response?.data?.userMessages[0] || 'Ошибка в запросе' })
      })
  }

  socketCallback({ data }: any): void {
    this.fileLink = data.file_url
    this.timerToDownloadFile = setTimeout(this.downloadFile, 1000)
  }

  downloadFile(): void {
    const element = document.createElement('a')
    element.setAttribute('href', this.fileLink as string)
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
    this.loading = false
  }
}
