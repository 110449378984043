import { Component, Vue, Watch } from 'vue-property-decorator'
import { PageModule } from '@/store/page'

import TheBar from '@/components/general/TheBar'
import TheDrawer from '@/components/general/TheDrawer'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import AppSnackbar from '@/components/app/AppSnackbar.vue'
import socketMixin from '@/plugins/socketMixin'

@Component({
  components: {
    TheBar,
    TheDrawer,
    AppSnackbar
  },
  mixins: [socketMixin]
})
export default class LayoutDefault extends Vue {
  expandOnHover = false
  onLine = navigator.onLine

  get isDark() { return PageModule.isDark }

  get primaryColor() {
    return PageModule.primaryColor
  }

  mounted() {
    this.$vuetify.theme.dark = false
  }

  render() {
    return <v-app
      dark={ this.isDark }
      light={ !this.isDark }
    >
      <TheBar />
      <TheDrawer />
      <AppSnackbar />

      <v-main>
        { this.$scopedSlots.default && this.$scopedSlots.default('defaultLayout') }
      </v-main>
    </v-app>
  }
}
