import { Component, Vue } from 'vue-property-decorator'
import { PageModule } from '@/store/page'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import AppSnackbar from '@/components/app/AppSnackbar.vue'

@Component({
  components: {
    AppSnackbar
  }
})
export default class LayoutDefault extends Vue {
  expandOnHover = false
  onLine = navigator.onLine

  get isDark() { return PageModule.isDark }

  get primaryColor() {
    return PageModule.primaryColor
  }

  render() {
    return <v-app
      dark={ this.isDark }
      light={ !this.isDark }
    >
      <div>
        { this.$scopedSlots.default && this.$scopedSlots.default('noneLayout') }
      </div>
      <AppSnackbar />
    </v-app>
  }
}
