
import { CreateElement, VNode } from 'vue'
import { Component, Vue } from 'vue-property-decorator'
import { VHover, VListItem } from 'vuetify/lib'
import { UserModule } from '@/store/user'
interface ProfileMenu {
  title: string;
  callbackClick: Function;
}

interface ProfileDivider {
  divider: boolean;
}

@Component({
  components: {
    'app-bar-item': {
      render(h: CreateElement): VNode {
        return h(VHover, {
          on: this.$listeners,
          scopedSlots: {
            default: ({ hover }: { hover: boolean}) => {
              return h(VListItem, {
                attrs: this.$attrs,
                class: {
                  'black--text': !hover,
                  'white--text primary elevation-12': hover
                },
                props: {
                  activeClass: '',
                  dark: hover,
                  link: true,
                  ...this.$attrs
                }
              }, this.$slots.default)
            }
          }
        })
      }
    }
  }
})
export default class TheHeaderMenu extends Vue {
  profile: Array<ProfileMenu | ProfileDivider> = [
    {
      title: 'Выйти',
      callbackClick: async() => {
        try {
          await UserModule.logout()
          localStorage.removeItem('token')

          this.$router.push('/login')
        } catch (error) {
          console.log({ error })
          if (error.isAxiosError) {
            // this.$sendNotification({ type: 'error', message: error?.response?.data?.userMessages[0] })
          }
        }
      }
    }
  ]

  get user() {
    return UserModule.user
  }

  render(h: CreateElement) {
    return h('v-menu', {
      props: {
        bottom: true,
        right: true,
        'offset-y': true,
        'min-width': '200',
        origin: 'top right',
        transition: 'scale-transition'
      },
      scopedSlots: {
        activator: ({ on, attrs }) => {
          return h('div', {
            on,
            props: attrs,
            class: 'ml-2 tt-uppercase d-flex ai-center'
          }, [
            <v-img src={ this.user?.image } width="35" height="35" class="circle mr-2" />,
            this.user?.firstName
          ])
        },
        default: () => {
          return <v-list tile={ false } flat nav>
            {
              this.profile.map((menuItem, i) => {
                if ((menuItem as ProfileDivider).divider) {
                  return <v-divider
                    key={ `divider-${i}` }
                    class="mb-2 mt-2"
                  />
                }

                return <app-bar-item
                  {...{
                    key: `item-${i}`,
                    nativeOn: {
                      click: (menuItem as ProfileMenu).callbackClick
                    }
                  }}
                >
                  <v-list-item-title>
                    { (menuItem as ProfileMenu).title }
                  </v-list-item-title>
                </app-bar-item>
              })
            }
          </v-list>
        }
      }
    })
  }
}
