import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import ru from 'vuetify/es5/locale/ru'

Vue.use(Vuetify)

const theme = {
  primary: '#471fbd',
  secondary: '#E91E63',
  accent: '#9C27b0',
  info: '#00CAE3'
}

export default new Vuetify({
  lang: {
    locales: { ru },
    current: 'ru'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      dark: theme,
      light: theme
    }
  }
})
