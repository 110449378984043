import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import '@/plugins/filters'
import VueMeta from 'vue-meta'

import VueI18n from 'vue-i18n'
import '@/plugins/socket'

import './style/index.styl'
import '@/plugins/swiper.js'

Vue.config.productionTip = false

Vue.use(VueI18n)
Vue.use(VueMeta)

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
