import { Component, Vue } from 'vue-property-decorator'
import LoginView from '@/components/page/login/LoginView'

@Component({
  components: {
    LoginView
  }
})
export default class LoginPage extends Vue {
  render() {
    return (
      <div class="fullscreen fullscreen--login">
        <LoginView/>
      </div>
    )
  }
}
