import { Component, Vue } from 'vue-property-decorator'

@Component
export default class NotFoundPage extends Vue {
  render() {
    return (
      <div class="fullscreen fullscreen--login">
        <div class="block login-view">
          Page Not Found
        </div>
      </div>
    )
  }
}
