import { Component, Vue } from 'vue-property-decorator'
import { PageModule } from '@/store/page'
import SecurityTabs from '@/components/page/security/SecurityTabs'

@Component({
  components: {
    SecurityTabs
  }
})
export default class SecurityPage extends Vue {
  render() {
    return (
      <div class="index pa-3">
        <SecurityTabs />
      </div>
    )
  }
}
