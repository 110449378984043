import { debounce, DebounceSettings } from 'lodash'
import { PageModule } from '@/store/page'
import { AxiosError } from 'axios'

export function HandleError(error: Error | AxiosError) {
  if ((error as AxiosError).isAxiosError) {
    PageModule.SEND_NOTIFICATION({ type: 'error', message: (error as AxiosError)?.response?.data?.userMessages[0] || 'Ошибка в запросе' })
  } else {
    PageModule.SEND_NOTIFICATION({ type: 'error', message: error.message })
  }
}

export function HandleApiRequest(options: { prop?: string; id?: string } = {}): MethodDecorator {
  let { prop, id } = options

  prop = prop || 'isLoading'

  return function(_target, _propertyKey, descriptor: PropertyDescriptor) {
    const method = descriptor.value as (...args: any[]) => any | Promise<any>

    descriptor.value = async function(...args: any[]) {
      const vm = this as any
      const setProp = (value: boolean) => {
        if (Object.prototype.hasOwnProperty.call(vm, prop || '')) {
          vm.$set(this, prop, value)
        }
      }

      setProp(true)

      try {
        return await method.apply(this, args)
      } catch (error) {
        HandleError(error)
      } finally {
        setProp(false)
      }
    }
  }
}

export function Debounce(time = 500, options?: DebounceSettings): MethodDecorator {
  const map = new Map()

  return function(_target, _propertyKey, descriptor: PropertyDescriptor) {
    const method = descriptor.value as (...args: any[]) => any

    descriptor.value = function(...args: any[]) {
      const _uid = (this as any)._uid

      if (map.has(_uid)) {
        return map.get(_uid)(...args)
      }

      const deb = debounce(method.bind(this), time, options)
      map.set(_uid, deb)
      return deb(...args)
    }
  }
}

export function Catch(): MethodDecorator {
  return function(_target, _propertyKey, descriptor: PropertyDescriptor) {
    const method = descriptor.value as (...args: any[]) => any | Promise<any>

    descriptor.value = async function(...args: any[]) {
      try {
        await method.apply(this, args)
      } catch (error) {
        HandleError(error)
      }
    }
  }
}
