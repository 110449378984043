import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import AppIcon from '@/components/app/AppIcon.vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import FormParser from '@/components/general/FormParser.vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import AppPhoneField from '@/components/general/AppPhoneField.vue'
import AppFilterSelector from '@/components/app/AppFilterSelector'
import AppFilterSelectorPlaces from '@/components/app/AppFilterSelectorPlaces'
import SelectionField from '@/components/general/SelectionField'
import OrderRating from '@/components/page/orders/OrderRating'
import OrdersTable from '@/components/page/orders/OrdersTable'
import DatePicker from '@/components/general/DatePicker'
import ExcelExportBackend from '@/components/app/ExcelExportBackend'
import { ComputedServiceForm, GeneralForm } from '@/models/service'
import { filter } from 'rxjs/operators'
import { UserModule } from '@/store/user'
import { $get, $post } from '@/plugins/axios'
import { InputSelectorItem, WebsocketEvent, WebsocketPayload } from '@/models'
import { HandleError } from '@/utils/helper'

@Component({
  components: {
    AppIcon,
    DatePicker,
    FormParser,
    OrdersTable,
    OrderRating,
    AppPhoneField,
    SelectionField,
    AppFilterSelector,
    AppFilterSelectorPlaces,
    ExcelExportBackend
  }
})
export default class OrdersTabs extends Vue {
  @Prop({ type: Number, default: null }) limitByServiceTypeId!: number

  searchField = ''
  searchNewField = ''
  query: Record<string, (number | string)[] | number | string | undefined> = JSON.parse(localStorage.getItem('orderSettingsSearch') || '{}')
  cachedValues: Record<string, number[]> = JSON.parse(localStorage.getItem('orderSettingsCache') || '{}')
  viewSettings = Object.keys(this.cachedValues).length > 0
  allBadge = 0
  newBadge = 0
  viewCreateOrderModal = false
  viewClearOrderModal = false
  order: Record<string, number[] | number | string | undefined | null> = {}
  orderGetFormLoading = false
  orderForm: null | GeneralForm = null
  autofocus = true
  clearFields = false
  oldData: Record<string, number | number[] | string[] | string | undefined | null> = { buildingId: 0, tenantId: 0, placeId: 0, customerId: 0 }
  isSelectWatchProps = true

  dict = {
    ratingOptions: [
      { id: 0, title: 'Без оценки' },
      { id: 1, title: '1' },
      { id: 2, title: '2' },
      { id: 3, title: '3' },
      { id: 4, title: '4' },
      { id: 5, title: '5' }
    ],
    reactionTimeOptions: [
      { id: 1, title: 'Время реакции - вовремя' },
      { id: 2, title: 'Время выполнения - вовремя' },
      { id: 3, title: 'Время реакции - просрочено' },
      { id: 4, title: 'Время выполнения - просрочено' }
    ]
  }

  tab = 'new'

  get tabs() {
    return [
      {
        title: 'Новые',
        value: 'new',
        badge: this.newBadge
      },
      {
        title: 'Все',
        value: 'all',
        badge: this.allBadge
      }
    ]
  }

  get user() {
    return UserModule.user
  }

  get finalQuery() {
    localStorage.setItem('orderSettingsSearch', JSON.stringify(this.query))

    const query = Object.entries(this.query).reduce<Record<string, string>>((acc, [key, value]) => {
      const keyValue = (value || [])
      acc[key] = keyValue + ''

      return acc
    }, {})

    return query
  }

  get serviceIdFilterUrl() {
    let url = 'services?'
    if (this.limitByServiceTypeId) {
      url += `serviceTypeId=${this.limitByServiceTypeId}`
    } else {
      url += 'hiddenServiceTypeId=5,6'
    }

    return url
  }

  get isDisableService(): boolean {
    return this.order.tenantId != null && this.order.buildingId != null && this.order.placeId != null && this.order.customerId != null
  }

  @Watch('query', { deep: true })
  HandleQueryChange() {
    this.$nextTick(() => {
      const keys = Object.keys(this.query)

      if (keys.length === 0) {
        localStorage.setItem('orderSettingsCache', '{}')
        return
      }

      keys.forEach(key => {
        const selector = (this.$refs as any)[`filter-${key}`]

        if (selector) {
          this.cachedValues[key] = selector.getSelected()
        }
      })

      localStorage.setItem('orderSettingsCache', JSON.stringify(this.cachedValues))
    })
  }

  @Watch('order.placeId')
  async HandlePlaceIdChange() {
    this.$nextTick(async() => {
      if (this.order.placeId && !this.order.buildingId) {
        const selector = this.$refs['orderForm-placeId'] as any
        const data = await $get(`/buildings/?placeId=${this.order.placeId}&tenantId=${this.order.tenantId}`)
        if (data.items.length === 1) {
          this.autofocus = false
          this.modalSetBuilding(data.items[0].id, data.items[0].title)
        }
      }

      if (!this.order.customerId && this.order.placeId) {
        const customerSelector = this.$refs['orderForm-customerId'] as any
        customerSelector.openSelector()
      }
    })
  }

  @Watch('order.buildingId')
  clearFormFieldsBuildingIdChange(newVal: any, oldVal: any) {
    if (oldVal && this.viewClearOrderModal === false && (this.order.unregisteredAddress != null || this.order.unregisteredCustomerName != null || this.order.unregisteredPhoneNumber != null || this.order.unregisteredEmail != null || this.orderForm?.orderForm.length)) {
      this.viewClearOrderModal = true
      this.oldData.buildingId = oldVal
      this.oldData.tenantId = this.order.tenantId
      return
    }
    if (oldVal && this.oldData.buildingId === 0 && this.oldData.tenantId === 0) {
      this.modalReset()
    }
  }

  @Watch('order.customerId')
  async HandleCustomerIdChange() {
    this.$nextTick(async() => {
      if (this.autofocus) {
        if (this.order.customerId && !this.order.placeId) {
          const placeSelector = this.$refs['orderForm-placeId'] as any
          placeSelector.openSelector()
        }
      }

      this.autofocus = true
    })
  }

  @Watch('order.tenantId')
  clearFormFieldsTenantIdChange(newVal: any, oldVal: any) {
    if (oldVal && this.viewClearOrderModal === false && (this.order.unregisteredAddress != null || this.order.unregisteredCustomerName != null || this.order.unregisteredPhoneNumber != null || this.order.unregisteredEmail != null || this.orderForm?.orderForm.length)) {
      this.viewClearOrderModal = true
      this.oldData.tenantId = oldVal
      this.oldData.buildingId = this.order.buildingId
      return
    }
    if (oldVal && this.oldData.tenantId === 0) {
      this.modalReset()
    }
  }

  @Watch('query.tenantId', { deep: true })
  resetQuery() {
    if (this.query.tenantId === '') this.query = {}
  }

  mounted() {
    const tab = localStorage.getItem('orderTab') || 'new'

    this.tab = tab

    const handleEvents: WebsocketEvent[] = [WebsocketEvent.NEW_CALL, WebsocketEvent.CRM_EXPORT_DOWNLOAD_LINK]
    const filterSocket = filter<WebsocketPayload>((value) => handleEvents.includes(value.name))

    this.$socket.pipe(filterSocket).subscribe((event) => {
      if (event.data.event === WebsocketEvent.NEW_CALL && event.data.customerId) {
        let url = ''
        /* eslint-disable */
        this.user?.tenants.forEach(item => {
          if (item.id === event.data.tenantId) {
            url = item.url
          }
        })
         /* eslint-disable */
        const link = document.createElement('a')
        link.href = `${url}/exploitation/manager/customers/${event.data.customerId}`
        link.setAttribute('target', '_blank')
        document.body.appendChild(link)
        link.click()
        link.remove()
        url = ''
      } else if (event.data.event === WebsocketEvent.NEW_CALL) this.setupCreateOrder(event.data)

      if (event.data.event === WebsocketEvent.CRM_EXPORT_DOWNLOAD_LINK && event.data.file_url) {
        const data = event.data;
        (this.$refs.export as ExcelExportBackend).socketCallback({ data });
      }
    })
  }

  toggleSettingsView() {
    this.viewSettings = !this.viewSettings
  }

  async setupCreateOrder(data: any) {
    this.viewCreateOrderModal = true
    await this.$nextTick()
    this.modalSetOrderSource(data.orderSourceId, data.orderSourceTitle)
    await this.$nextTick()
    this.modalSetTenant(data.tenantId, data.tenantTitle)
    await this.$nextTick()
    this.modalSetBuilding(data.buildingId, data.buildingTitle)
    await this.$nextTick()
    this.modalSetPlace(data.placeId, data.placeAddress)
    await this.$nextTick()
    this.modalSetCustomer(data.customerId, data.customerFullName, data.customerPhoneNumber)
  }

  modalSetOrderSource(orderSourceId: number | string, orderSourceTitle: string) {
    orderSourceId = orderSourceId.toString()
    const selector = this.$refs['orderForm-orderSourceId'] as any
    if (orderSourceId) {
      this.order.orderSourceId = orderSourceId.toString()
      selector.setSelected([
        {
          id: orderSourceId,
          title: orderSourceTitle
        }
      ])
    } else {
      this.order.orderSourceId = ''
      selector.setSelected([
        {
          id: '',
          title: '-'
        }
      ])
    }
  }

  modalSetTenant(tenantId: number, tenantTitle: string) {
    const selector = this.$refs['orderForm-tenantId'] as any
    if (tenantId) {
      this.order.tenantId = tenantId.toString()
      selector.setSelected([
        {
          id: tenantId,
          title: tenantTitle
        }
      ])
    } else {
      this.order.tenantId = ''
      selector.setSelected([
        {
          id: '',
          title: '-'
        }
      ])
    }
  }

  modalSetBuilding(buildingId: number, buildingTitle: string) {
    const selector = this.$refs['orderForm-buildingId'] as any

    if (buildingId) {
      this.order.buildingId = buildingId.toString()
      selector.setSelected([
        {
          id: buildingId,
          title: buildingTitle
        }
      ])
    } else {
      this.order.buildingId = ''
      selector.setSelected([
        {
          id: '',
          title: '-'
        }
      ])
    }
  }

  modalSetPlace(placeId: number, placeAddress: string) {
    const selector = this.$refs['orderForm-placeId'] as any

    if (placeId) {
      this.order.placeId = placeId.toString()
      selector.setSelected([
        {
          id: placeId,
          title: placeAddress
        }
      ])
    } else {
      this.order.placeId = '0'
      selector.setSelected([
        {
          id: 0,
          title: this.$t('components.modalOrder.unknownPlace')
        }
      ])
    }
  }

  modalSetCustomer(customerId: number, customerFullName: string, customerPhoneNumber: string) {
    const selector = this.$refs['orderForm-customerId'] as any

    if (customerId) {
      this.order.customerId = customerId.toString()
      selector.setSelected([
        {
          id: customerId,
          title: customerFullName
        }
      ])
      this.order.unregisteredPhoneNumber = ''
    } else {
      this.order.customerId = '0'
      selector.setSelected([
        {
          id: 0,
          title: this.$t('components.modalOrder.unknownCustomer')
        }
      ])

      if (customerPhoneNumber) {
        this.order.unregisteredPhoneNumber = customerPhoneNumber
      }
    }
  }

  modalReset() {
    this.order = {
      orderSourceId: this.order.orderSourceId,
      tenantId: this.order.tenantId,
      buildingId: this.order.buildingId,
      placeId: null,
      customerId: null,
      unregisteredAddress: null,
      unregisteredCustomerName: null,
      unregisteredPhoneNumber: null,
      unregisteredEmail: null
    }
    this.orderForm = null
    this.viewClearOrderModal = false
  }

  closeResetModal() {
    this.order.buildingId = this.oldData.buildingId as number
    this.order.tenantId = this.oldData.tenantId as number
    this.$nextTick(() => {
      this.viewClearOrderModal = false
      this.oldData.buildingId = 0
      this.oldData.tenantId = 0
    })
  }

  openOrderForm(): void {
    this.isSelectWatchProps = true
    this.viewCreateOrderModal = true
  }

  render() {
    return <div>
      <v-dialog
        max-width="355px"
        max-height="90vmin"
        value={ this.viewClearOrderModal }
        onInput={ (value: boolean) => { this.viewClearOrderModal = value } }
      >
        <v-card>
          <v-card-title>
            Данные формы будут очищены, вы уверены?
          </v-card-title>
          <v-card-actions class="justify-space-around">
            <v-btn onClick={ this.modalReset } color="primary">Очистить</v-btn>
            <v-btn ref='cancel-btn' class='cancel-btn' onClick={ this.closeResetModal }>Отменить</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        max-width="500px"
        max-height="90vmin"
        value={ this.viewCreateOrderModal }
        onInput={ (value: boolean) => { this.viewCreateOrderModal = value } }
      >
        <v-form ref='createForm'>
          <v-card>
            <v-card-title> Новое обращение </v-card-title>
            <v-card-text>
              <AppFilterSelector
                label='Канал обращения'
                url='ordersources'
                dense
                hide-details
                ref='orderForm-orderSourceId'
                value={ this.order.orderSourceId }
                reduce-items={ (items: InputSelectorItem[]) => items.filter((item) => Number(item.isCRM) > 0) }
                onInput={ (value: number) => { this.order = { ...this.order, orderSourceId: value } } }
              />
              <AppFilterSelector
                label='Управляющая организация'
                dense
                hide-details
                ref='orderForm-tenantId'
                options={ this.user?.tenants }
                value={ this.order.tenantId }
                onInput={ (value: number) => { this.order = { ...this.order, tenantId: value } } }
              />
              <AppFilterSelector
                label='Объект'
                url='buildings'
                ref='orderForm-buildingId'
                dense
                hide-details
                show-image={ false }
                disabled={ !this.order.tenantId }
                query={ { tenantId: this.order.tenantId } }
                watch-query={ { tenantId: this.order.tenantId || '' } }
                watch-props={ this.isSelectWatchProps }
                value={ this.order.buildingId }
                onInput={ (value: number) => { this.order = { ...this.order, buildingId: value } } }
              />
              <v-row>
                <v-col cols="6">
                  <AppFilterSelectorPlaces
                    label='Помещение'
                    dense
                    hide-details
                    disabled={ !this.order.tenantId }
                    show-empty={ false }
                    url='places'
                    ref='orderForm-placeId'
                    query={ { tenantId: this.order.tenantId } }
                    watch-query={ { buildingIds: this.order.buildingId || '', customerId: this.order.customerId || '' } }
                    watch-props={ this.isSelectWatchProps }
                    value={ this.order.placeId }
                    options={ [{ title: '-- Неизвестно --', id: 0 }] }
                    onInput={ (value: number[]) => { this.order = { ...this.order, placeId: value } } }
                  />
                </v-col>
                <v-col cols="6">
                  <AppFilterSelector
                    label='Клиент'
                    dense
                    hide-details
                    disabled={ !this.order.tenantId }
                    url='customers'
                    ref='orderForm-customerId'
                    show-empty={ false }
                    query={ {
                      buildingId: this.order.buildingId || '',
                      tenantId: this.order.tenantId
                    } }
                    watch-query={ { placeId: this.order.placeId || '' } }
                    watch-props={ this.isSelectWatchProps }
                    options={ [{ title: '-- Не зарегистрирован --', id: 0 }] }
                    value={ this.order.customerId }
                    onInput={ (value: number[]) => {
                      this.order = { ...this.order, customerId: value }
                    } }
                  />
                </v-col>
              </v-row>
              <div v-show={ this.order.placeId === 0 }>
                <v-text-field
                  dense
                  outlined
                  hide-details
                  disabled={ !this.order.tenantId }
                  color="secondary"
                  onInput={ (value: string) => { this.order.unregisteredAddress = value }}
                  label="Место"
                  type="text"
                  class="mb-2"
                />
              </div>
              <div v-show={ this.order.customerId === 0 }>
                <v-text-field
                  dense
                  outlined
                  hide-details
                  color="secondary"
                  onInput={ (value: string) => { this.order.unregisteredCustomerName = value }}
                  label="ФИО"
                  type="text"
                  class="mb-2"
                />
                <v-row>
                  <v-col cols="7">
                    <AppPhoneField outlined dense onInput={ (value: string) => { this.order.unregisteredPhoneNumber = value }} value={ this.order.unregisteredPhoneNumber } />
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      dense
                      hide-details
                      color="secondary"
                      onInput={ (value: string) => { this.order.unregisteredEmail = value }}
                      label="Email"
                      type="text"
                    />
                  </v-col>
                </v-row>
              </div>
              <AppFilterSelector
                label='Форма заявки'
                disabled={ !this.isDisableService }
                url='services'
                dense
                hide-details
                query={ {
                  currentTenantUser: this.order.tenantId,
                  buildingId: this.order.buildingId,
                  tenantId: this.order.tenantId,
                  serviceStatusId: '1,4'
                } }
                watch-query={ { tenantId: this.order.tenantId || '', buildingId: this.order.buildingId || '', currentTenantUser: this.order.tenantId || '' } }
                value={ this.order.serviceId }
                watch-props={ this.isSelectWatchProps }
                showImage={ false }
                onInput={ async(value: number) => {
                  try {
                    if (value.toString() !== '') {
                      this.order = { ...this.order, serviceId: value }
                      this.orderGetFormLoading = true
                      this.orderForm = await $get<GeneralForm>(`/services/${value}`, { params: { tenantId: this.order.tenantId } })
                    }
                  } catch (error) {
                    if (value === null) {
                      return
                    } HandleError(error)
                  } finally {
                    this.orderGetFormLoading = false
                  }
                } }
              />
              {
                this.orderGetFormLoading ? <div
                  class="text-center py-7"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  />
                </div> : this.orderForm?.orderForm
                  ? <FormParser
                    form={ this.orderForm?.orderForm }
                    serviceId={ this.order.serviceId as string }
                    tenantId={ this.order.tenantId as string }
                    callbackForm={ async(form: ComputedServiceForm) => {
                      const isUnselectedUser = this.order.customerId === 0
                      const isUnselectedPlace = this.order.placeId === 0

                      const data = {
                        buildingId: this.order.buildingId as string,
                        customerId: this.order.customerId as string,
                        tenantId: this.order.tenantId as string,
                        orderData: form,
                        orderSourceId: this.order.orderSourceId as string,
                        placeId: this.order.placeId as string,
                        serviceId: this.order.serviceId as string,
                        orderAppointmentTime: '',
                        unregisteredAddress: isUnselectedPlace ? this.order.unregisteredAddress as string : '',
                        unregisteredCustomerName: isUnselectedUser ? this.order.unregisteredCustomerName as string : '',
                        unregisteredPhoneNumber: isUnselectedUser ? this.order.unregisteredPhoneNumber as string : '',
                        unregisteredEmail: isUnselectedUser ? this.order.unregisteredEmail as string : ''
                      }

                      const { urlToTenant } = await $post('orders', data, { params: { tenantId: this.order.tenantId } })
                      this.isSelectWatchProps = false
                      window.open(urlToTenant)

                      this.viewCreateOrderModal = false

                      this.$nextTick(() => {
                        this.order = {}
                        this.orderForm = null
                        return
                      })
                    }}
                  />
                  : null
              }
            </v-card-text>
          </v-card>
        </v-form>
      </v-dialog>

      <v-tabs
        value={ this.tab }
        align-with-title
        onChange={ (tab: string) => { localStorage.setItem('orderTab', tab) } }
      >
        {
          this.tabs.map((tab) => {
            return <v-tab link={ true } href={ '#' + tab.value }>
              { tab.title }
              { tab.badge ? <span class="badge"> { tab.badge } </span> : null }
            </v-tab>
          })
        }
        <v-tab-item value='new' transition='false'>
          <div class="pa-3">
            <v-row justify="space-between">
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  onClick={  this.openOrderForm }
                >
                  <v-icon left> mdi-plus </v-icon>
                    Добавить
                </v-btn>
              </v-col>
            </v-row>
            <OrdersTable
              search={ this.searchNewField }
              query={ { orderStatusId: 1, tab: 'new' } }
              isAll = { false }
            />
          </div>
        </v-tab-item>
        <v-tab-item value='all' transition='false'>
          <div class="pa-3">
            <v-row justify="space-between">
              <v-col cols="auto">
                <div class="margin-every">
                  <v-btn onClick={ this.toggleSettingsView } color="green" class="bg-small" fab x-small tile>
                    <v-icon> { `mdi-${this.viewSettings ? 'minus' : 'plus'}` } </v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    onClick={ () => { this.viewCreateOrderModal = true } }
                  >
                    <v-icon left> mdi-plus </v-icon>
                      Добавить
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="6" lg="3" class="ml-auto">
                <v-text-field
                  dense
                  color="secondary"
                  onInput={ (value: string) => { this.searchField = value }}
                  label="Поиск..."
                  outlined
                  hide-detaild
                  type="text"
                />
              </v-col>
              <v-col cols="auto">
                <ExcelExportBackend ref='export' url="orders" query={ this.finalQuery } />
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-row v-show={ this.viewSettings }>
                <v-col cols="3">
                <AppFilterSelector
                    label='Управляющая организация'
                    dense
                    hide-details
                    options={ this.user?.tenants }
                    value={ this.query.tenantId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, tenantId: value } } }
                  />
                  <v-text-field
                    color="secondary"
                    value={ this.query.issueId }
                    onInput={ (value: number) => { this.query = { ...this.query, issueId: [value] } }}
                    label="Номер заявки"
                    type="number"
                    dense
                    hide-details
                    outlined
                  />
                  <AppFilterSelector
                    label='Статус'
                    url='orderstatuses'
                    ref='filter-orderStatusId'
                    options={ this.cachedValues.orderStatusId }
                    dense
                    chips
                    multiple
                    hide-details
                    value={ this.query.orderStatusId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, orderStatusId: value } } }
                  />
                  <AppFilterSelector
                    label='Канал обращения'
                    url='ordersources'
                    ref='filter-orderSourceId'
                    options={ this.cachedValues.orderSourceId }
                    multiple
                    dense
                    hide-details
                    value={ this.query.orderSourceId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, orderSourceId: value } } }
                  />
                  <AppFilterSelector
                    label='Рейтинг'
                    dense
                    hide-details
                    options={ this.dict.ratingOptions }
                    value={ this.query.rating }
                    onInput={ (value: number[]) => { this.query = { ...this.query, rating: value } } }
                  />
                </v-col>
                <v-col cols="3">
                  <AppFilterSelector
                    label='Тип услуги'
                    dense
                    hide-details
                    show-empty={ true }
                    url='servicetypes'
                    ref='filter-serviceTypeId'
                    options={ this.cachedValues.serviceTypeId }
                    value={ this.query.serviceTypeId }
                    onInput={ (value: number) => { this.query = { ...this.query, serviceTypeId: value } } }
                  />
                  <AppFilterSelector
                    label='Название услуги'
                    ref='filter-serviceId'
                    dense
                    hide-details
                    disabled={ !this.query.tenantId }
                    options={ this.cachedValues.serviceId }
                    url='services'
                    query={this.limitByServiceTypeId ? { serviceTypeId: this.limitByServiceTypeId } : { hiddenServiceTypeId: '5,6' } }
                    value={ this.query.serviceId }
                    onInput={ (value: number) => { this.query = { ...this.query, serviceId: value } } }
                  />
                  <AppFilterSelector
                    label='Просрочена'
                    options={ this.dict.reactionTimeOptions }
                    dense
                    multiple
                    hide-details
                    value={ this.query.reactionTime }
                    onInput={ (value: number[]) => { this.query = { ...this.query, reactionTime: value } } }
                  />
                  <AppFilterSelector
                    label='Тип заявки'
                    dense
                    hide-details
                    url='ordertypes'
                    ref='filter-orderTypeId'
                    options={ this.cachedValues.orderTypeId }
                    value={ this.query.orderTypeId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, orderTypeId: value } } }
                  />
                  <AppFilterSelector
                    label='Суть обращения'
                    dense
                    hide-details
                    url='orderissuetypes'
                    ref='filter-orderIssueTypeId'
                    options={ this.cachedValues.orderIssueTypeId }
                    value={ this.query.orderIssueTypeId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, orderIssueTypeId: value } } }
                  />
                </v-col>
                <v-col cols="3">
                  <AppFilterSelector
                    label='Группа объектов'
                    url='buildingGroups'
                    ref='filter-buildingGroupId'
                    dense
                    hide-details
                    disabled={ !this.query.tenantId }
                    options={ this.cachedValues.buildingGroupId }
                    value={ this.query.buildingGroupId }
                    query={ { tenantId: this.query.tenantId } }
                    onInput={ (value: number[]) => { this.query = { ...this.query, buildingGroupId: value } } }
                  />
                  <AppFilterSelector
                    label='Квартал'
                    url='buildingsubgroups'
                    dense
                    hasSearch
                    hide-details
                    disabled={ !this.query.tenantId }
                    ref='filter-buildingSubGroupId'
                    query={ { tenantId: this.query.tenantId } }
                    options={ this.cachedValues.buildingSubGroupId }
                    value={ this.query.buildingSubGroupId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, buildingSubGroupId: value } } }
                  />
                  <AppFilterSelector
                    label='Объект'
                    url='buildings'
                    dense
                    chips
                    multiple
                    hide-details
                    disabled={ !this.query.tenantId }
                    value={ this.query.buildingId }
                    query={ { tenantId: this.query.tenantId } }
                    ref='filter-buildingSubGroupId'
                    options={ this.cachedValues.buildingSubGroupId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, buildingId: value } } }
                  />
                  <AppFilterSelector
                    label='Помещение'
                    url='places'
                    ref='filter-buildingId'
                    hide-details
                    dense
                    disabled={ !this.query.tenantId }
                    options={ this.cachedValues.buildingId }
                    query={ { buildingIds: this.query.buildingId || '' } }
                    value={ this.query.placeId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, placeId: value } } }
                  />
                  <v-row dense>
                    <v-col cols="6">
                      <v-text-field
                        color="secondary"
                        value={ this.query.placeEntranceNumber }
                        onInput={ (value: number) => { this.query = { ...this.query, placeEntranceNumber: [value] } }}
                        label="Подъезд"
                        type="number"
                        dense
                        outlined
                        hide-details
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        color="secondary"
                        value={ this.query.placeFloor }
                        onInput={ (value: number) => { this.query = { ...this.query, placeFloor: [value] } }}
                        label="Этаж"
                        type="number"
                        dense
                        outlined
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <AppFilterSelector
                    label='Клиент'
                    url='customers'
                    full-width
                    no-filter
                    dense
                    hide-details
                    ref='filter-customerId'
                    disabled={ !this.query.tenantId }
                    query={ { tenantId: this.query.tenantId } }
                    options={ this.cachedValues.customerId }
                    value={ this.query.customerId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, customerId: value } } }
                  />
                </v-col>
                <v-col cols="3">
                  <AppFilterSelector
                    label='Подразделение'
                    url='departments'
                    multiple
                    chips
                    dense
                    hide-details
                    ref='filter-responsibleDeptId'
                    disabled={ !this.query.tenantId }
                    query={ { tenantId: this.query.tenantId } }
                    options={ this.cachedValues.responsibleDeptId }
                    value={ this.query.responsibleDeptId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, responsibleDeptId: value } } }
                  />
                  <AppFilterSelector
                    label='Исполнитель'
                    url='users'
                    ref='filter-responsibleUserId'
                    dense
                    hide-details
                    disabled={ !this.query.tenantId }
                    options={ this.cachedValues.responsibleUserId }
                    value={ this.query.responsibleUserId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, responsibleUserId: value } } }
                  />
                  <AppFilterSelector
                    label='Заявку принял'
                    url='users'
                    ref='filter-acceptedOrderUserId'
                    dense
                    hide-details
                    disabled={ !this.query.tenantId }
                    options={ this.cachedValues.acceptedOrderUserId }
                    value={ this.query.acceptedOrderUserId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, acceptedOrderUserId: value } } }
                  />
                  <AppFilterSelector
                    label='Заявку закрыл'
                    url='users'
                    ref='filter-closeOrderUserId'
                    dense
                    hide-details
                    disabled={ !this.query.tenantId }
                    options={ this.cachedValues.closeOrderUserId }
                    value={ this.query.closeOrderUserId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, closeOrderUserId: value } } }
                  />
                  <AppFilterSelector
                    label='Управляющий кварталом'
                    url='users/group-management'
                    ref='filter-managerSubGroupId'
                    dense
                    outlined
                    hide-details
                    disabled={ !this.query.tenantId }
                    options={ this.cachedValues.managerSubGroupId }
                    value={ this.query.managerSubGroupId }
                    onInput={ (value: number[]) => { this.query = { ...this.query, managerSubGroupId: value } } }
                  />
                  <DatePicker
                    label={ 'Создано' }
                    name="createdAt"
                    dense
                    outlined
                    hide-details
                    value={ this.query.createdAt }
                    onInput={ (value: string[]) => { this.$set(this.query, 'createdAt', value) }}
                  />
                  <DatePicker
                    label={ 'Обновлено' }
                    name="updatedAt"
                    dense
                    outlined
                    hide-details
                    value={ this.query.updatedAt }
                    onInput={ (value: string[]) => { this.$set(this.query, 'updatedAt', value) }}
                  />
                </v-col>
                { Object.values(this.query).length ? <v-col cols="auto" class="mb-3">
                  <v-btn
                    color="primary"
                    onClick={ () => { this.query = {} } }
                  >
                    Очистить
                  </v-btn>
                </v-col> : null }
              </v-row>
            </v-expand-transition>

            <OrdersTable
              search={ this.searchField }
              query={ this.finalQuery }
            />
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
  }
}
