import Vue from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

dayjs.locale('ru')

Vue.filter('ellipsis', (str: string, length: number, maxLineLength?: number) => {
  if (str.length > length) {
    str = str.slice(0, length - str.length - 3) + '...'
  }
  if (maxLineLength && str.split(' ').some(word => word.length > maxLineLength)) {
    str = str.trim().slice(0, maxLineLength - str.length - 3) + '...'
  }

  return str
})

Vue.filter('dateTime', function(timestamp: number) {
  if (!timestamp || !dayjs.unix(timestamp).isValid()) {
    return ''
  }

  return dayjs.unix(timestamp).format('D MMMM, HH:mm')
})

Vue.filter('dateOnly', function(timestamp: number) {
  if (!timestamp || !dayjs.unix(timestamp).isValid()) {
    return ''
  }

  return dayjs.unix(timestamp).format('D MMMM, YYYY')
})

Vue.filter('fullDateTime', function(timestamp: number) {
  if (!timestamp || !dayjs.unix(timestamp).isValid()) {
    return ''
  }
  if (+dayjs.unix(timestamp).format('YYYY') === new Date().getFullYear()) {
    return dayjs.unix(timestamp).format('D MMMM, HH:mm')
  }
  return dayjs.unix(timestamp).format('D MMMM YYYY, HH:mm')
})

Vue.filter('phoneFormat', function(number: string | number) {
  return number
    ? number
      .toString()
      .replace(/(\d)(\d\d\d)(\d\d\d)(\d\d)(\d\d)/, '+$1 ($2) $3-$4-$5')
    : number
})

Vue.filter('moneyFormat', function(number: number) {
  return new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB'
  }).format(number)
})
