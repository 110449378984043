import { Component, Prop, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'

@Component({
  template: `
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :id="name"
          :value="value"
          :label="label"
          v-bind="$attrs"
          readonly
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
      v-model="innerValue"
        :max="currentDay"
        range
        :first-day-of-week="1"
        color="primary"
      >
      </v-date-picker>
    </v-menu>
  `
})
export default class DatePicker extends Vue {
  @Prop({ type: String, default: 'label' }) label!: string
  @Prop({ type: String, default: 'label' }) name!: string
  @Prop({ type: Array, default: () => [] }) value!: string[]

  menu = false
  inputValue: string[] = []

  get currentDay() {
    return dayjs().format('YYYY-MM-DD')
  }

  get innerValue() {
    return this.inputValue
  }

  set innerValue(value: string[]) {
    this.inputValue = value

    if (this.inputValue.length === 2) {
      const finalValue = [value.map(time => dayjs(time).format('DD.MM.YYYY')).join('-')]

      if (JSON.stringify(finalValue) !== JSON.stringify(this.value)) {
        this.$emit('input', finalValue)
      }
    }
  }
}
